import { useEffect, useState } from "react";
import { BlobProvider, Document, Page, Image, Text, View, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import db from "../../firebase";
import { collection, getDocs } from "firebase/firestore";

const SalePurchaseQuote = (props) => {
    const [loading, setLoading] = useState(false);
    const [prices, setPrices] = useState(false);
    // Get Prices
    const getPrices = async () => {
        setLoading(true);
        const pricesCollectionRef = collection(db, "prices");
        await getDocs(pricesCollectionRef)
        .then((querySnapshot)=>{
            const pricesData = querySnapshot.docs
               .map((doc) => ({...doc.data(), id:doc.id }));
            setPrices(pricesData);
            setLoading(false);
        })
    }

    // Sale Quote
    const saleEnteredPrice = props.saleFormData.enteredSalePrice;
    const formType = props.formType;
    const [saleLegalFee, setSaleLegalFee] = useState(0);
    const [saleIdCheck, setSaleIdCheck] = useState(0);
    const [saleLawyerChecker, setSaleLawyerChecker] = useState(0);
    const [saleTtFee, setSaleTtFee] = useState(0);
    const [saleLandSearch, setSaleLandSearch] = useState(0);
    const [saleLeaseholdFlat, setSaleLeaseholdFlat] = useState(0);
    const [saleLeaseholdHouse, setSaleLeaseholdHouse] = useState(0);
    const [saleSharedOwner, setSaleSharedOwner] = useState(0);
    const [saleAuctionSalePur, setSaleAuctionSalePur] = useState(0);
    const [saleBridgingFinance, setSaleBridgingFinance] = useState(0);
    const [saleCompaniesHouse, SetSaleCompaniesHouse] = useState(0);
    const [saleCompaniesHouseCharge, SetSaleCompaniesHouseCharge] = useState(0);
    const [saleLandSearchCompany, setSaleLandSearchCompany] = useState(0);
    const [saleSpecialLender, setSaleSpecialLender] = useState(0);
    const [saleProbate, setSaleProbate] = useState(0);
    const [saleTotalQuote, setSaleTotalQuote] = useState(0);
    const [saleDiscount, setSaleDiscount] = useState(0);
    const fixPrice = 500;

    let pdfbase = '';
    //Instruct Click
    const instructClickHandler = () => {
        props.instructClickHandler(pdfbase);
    };
    const saleFeeCalculations = () => {
        if(prices.length > 0) {
            setLoading(true);
            setSaleIdCheck(parseInt(prices[1].idCheck) * props.saleFormData.numberSellers);
            setSaleLawyerChecker(parseInt(prices[1].lawyerChecker));
            setSaleTtFee(parseInt(prices[1].ttFee));
            setSaleLandSearch(parseInt(prices[1].landSearch));
            if(props.source === 'Mortgage Advice UK / Whitehorns') {
                setSaleDiscount(100);
            }
            if(props.saleFormData.propertyType === 'Flat' && props.saleFormData.propertyHold === 'Leasehold') {
                if(formType === 'Sale') {
                    setSaleLeaseholdFlat(parseInt(prices[1].leaseholdFlat));
                } else if(formType === "Remortgage") {
                    setSaleLeaseholdFlat(parseInt(prices[1].leaseholdFlatRemortgage));
                }
            } else if(props.saleFormData.propertyType === 'House' && props.saleFormData.propertyHold === 'Leasehold') {
                setSaleLeaseholdHouse(parseInt(prices[1].leaseholdHouse));
            }
            if(props.saleAuctionInfo[1].checked === true) {
                setSaleAuctionSalePur(parseInt(prices[1].auctionSalePur));
            }
            if(props.saleFormData.mortgageLender === 'Castle Trust Capital plc' || props.saleFormData.mortgageLender === 'Fleet Mortgages' || props.saleFormData.mortgageLender === 'Foundation Home Loans' || props.saleFormData.mortgageLender === 'Interbay Commercial' || props.saleFormData.mortgageLender === 'Landbay - Buy to Let' || props.saleFormData.mortgageLender === 'Paragon Mortgages' || props.saleFormData.mortgageLender === 'The Paragon Group' || props.saleFormData.mortgageLender === 'Precise Mortgages') {
                setSaleSpecialLender(parseInt(250));
            }
            if(props.saleAuctionInfo[3].checked == true) {
                setSaleProbate(parseInt(75))
            }
            if(props.saleFormData.purchaseSchemes === 'Shared Ownership Scheme') {
                setSaleSharedOwner(parseInt(prices[1].sharedOwner));
            }
            if(props.saleFormData.bridgingFinance === 'Yes') {
                setSaleBridgingFinance(parseInt(prices[1].bridgingFinance));
            }
            if(props.aboutDetails.usingCompany === 'Yes' && props.saleFormData.gettingMortgage === 'Yes') {
                SetSaleCompaniesHouse(parseInt(prices[1].companiesHouse));
                SetSaleCompaniesHouseCharge(parseInt(prices[1].companiesHouseCharge));
                setSaleLandSearchCompany(parseInt(prices[1].landSearchCompany));
            }
            //Base Fee Calculation
            if(saleEnteredPrice < 150001) {
                setSaleLegalFee(parseInt(prices[1].legalFee1));
            } else if(saleEnteredPrice > 150000 && saleEnteredPrice < 250001) {
                setSaleLegalFee(parseInt(prices[1].legalFee2));
            } else if(saleEnteredPrice > 250000 && saleEnteredPrice < 500001) {
                setSaleLegalFee(parseInt(prices[1].legalFee3));
            } else if(saleEnteredPrice > 500000 && saleEnteredPrice < 750001) {
                setSaleLegalFee(parseInt(prices[1].legalFee4));
            } else if(saleEnteredPrice > 750000 && saleEnteredPrice < 1000001) {
                setSaleLegalFee(parseInt(prices[1].legalFee5));
            } else if(saleEnteredPrice > 1000000 && saleEnteredPrice < 2000001) {
                setSaleLegalFee(parseInt(prices[1].legalFee6));
            } else if(saleEnteredPrice > 2000000) {
                setSaleLegalFee(parseInt(Number(saleEnteredPrice) * 0.10 / 100) );
            } 
            setLoading(false);
        }
    };

    const calculateSaleTotal = () => {
        setLoading(true);
        if(saleLegalFee > 0) {
            setSaleTotalQuote(saleLegalFee + saleIdCheck + saleLawyerChecker + saleTtFee + saleLeaseholdFlat + saleLeaseholdHouse + saleBridgingFinance + saleProbate + saleSharedOwner + saleAuctionSalePur + saleCompaniesHouse + saleCompaniesHouseCharge + saleSpecialLender + saleLandSearch + saleLandSearchCompany - saleDiscount);            
        }
        setLoading(false);
    }

    // Purchase Quote
    const purEnteredPrice = props.purchaseFormData.enteredPurchasePrice;
    const [purLegalFee, setPurLegalFee] = useState(0);
    const [purIdCheck, setPurIdCheck] = useState(0);
    const [purLawyerChecker, setPurLawyerChecker] = useState(0);
    const [purTtFee, setPurTtFee] = useState(0);
    const [purLandSearch, setPurLandSearch] = useState(0);
    const [purSearches, setPurSearches] = useState(0);
    const [purStampDutyLandTax, setPurStampDutyLandTax] = useState(0);
    const [purLeaseholdFlat, setPurLeaseholdFlat] = useState(0);
    const [purLeaseholdHouse, setPurLeaseholdHouse] = useState(0);
    const [purNewBuild, setPurNewBuild] = useState(0);
    const [purSharedOwner, setPurSharedOwner] = useState(0);
    const [purIsaScheme, setPurIsaScheme] = useState(0);
    const [purUnregisteredProperty, setPurUnregisteredProperty] = useState(0);
    const [purAuctionSalePur, setPurAuctionSalePur] = useState(0);
    const [purBridgingFinance, setPurBridgingFinance] = useState(0);
    const [purCompaniesHouse, SetPurCompaniesHouse] = useState(0);
    const [purCompaniesHouseCharge, SetPurCompaniesHouseCharge] = useState(0);
    const [purLandSearchCompany, setPurLandSearchCompany] = useState(0);
    const [purSpecialLender, setPurSpecialLender] = useState(0);
    const [purGifted, setPurGifted] = useState(0);
    const [purStampDuty, setPurStampDuty] = useState(0);
    const [purHmLandRegFee, setPurHmLandRegFee] = useState(0);
    const [purTotalQuote, setPurTotalQuote] = useState(0);
    const [purTotalQuoteWithoutStampDuty, setPurTotalQuoteWithoutStampDuty] = useState(0);
    const [purIfNewBuild, setPurIfNewBuild] = useState(false);
    const [purDiscount, setPurDiscount] = useState(0);
    
    const purFeeCalculations = () => {
        if(prices.length > 0) {
            setLoading(true);
            setPurIdCheck(parseInt(prices[0].idCheck) * props.purchaseFormData.numberSellers);
            setPurLawyerChecker(parseInt(prices[0].lawyerChecker));
            setPurTtFee(parseInt(prices[0].ttFee));
            setPurLandSearch(parseInt(prices[0].landSearch));
            setPurSearches(parseInt(prices[0].searches));
            setPurStampDutyLandTax(parseInt(prices[0].stampDutyLandTax));
            if(props.source === 'Mortgage Advice UK / Whitehorns') {
                setPurDiscount(100);
            }
            if(props.purchaseFormData.propertyType === 'Flat' && props.purchaseFormData.propertyHold === 'Leasehold') {
                setPurLeaseholdFlat(parseInt(prices[0].leaseholdFlat));
            } else if(props.purchaseFormData.propertyType === 'House' && props.purchaseFormData.propertyHold === 'Leasehold') {
                setPurLeaseholdHouse(parseInt(prices[0].leaseholdHouse));
            }
            if (props.purchaseAuctionInfo[1].checked === true) {
                setPurNewBuild(parseInt(prices[0].newBuild));
                setPurIfNewBuild(true);
            }
            if (props.purchaseAuctionInfo[2].checked === true) {
                setPurUnregisteredProperty(parseInt(prices[0].unregisteredProperty));
            }
            if(props.purchaseAuctionInfo[3].checked === true) {
                setPurAuctionSalePur(parseInt(prices[0].auctionSalePur));
            }
            if(props.purchaseFormData.mortgageLender === 'Castle Trust Capital plc' || props.purchaseFormData.mortgageLender === 'Fleet Mortgages' || props.purchaseFormData.mortgageLender === 'Foundation Home Loans' || props.purchaseFormData.mortgageLender === 'Interbay Commercial' || props.purchaseFormData.mortgageLender === 'Landbay - Buy to Let' || props.purchaseFormData.mortgageLender === 'Paragon Mortgages' || props.purchaseFormData.mortgageLender === 'The Paragon Group' || props.purchaseFormData.mortgageLender === 'Precise Mortgages') {
                setPurSpecialLender(parseInt(250));
            }
            if(props.purchaseFormData.gifted === 'Yes') {
                setPurGifted(parseInt(125));
            }
            if(props.purchaseFormData.purchaseSchemes === 'Shared Ownership Scheme') {
                setPurSharedOwner(parseInt(prices[0].sharedOwner));
            }
            if(props.purchaseFormData.purchaseSchemes === 'Help to Buy ISA scheme') {
                setPurIsaScheme(parseInt(prices[0].isaScheme * props.purchaseFormData.numberSellers));
            }
            if(props.purchaseFormData.bridgingFinance === 'Yes') {
                setPurBridgingFinance(parseInt(prices[0].bridgingFinance));
            }
            if(props.aboutDetails.usingCompany === 'Yes' && props.purchaseFormData.gettingMortgage === 'Yes') {
                SetPurCompaniesHouse(parseInt(prices[0].companiesHouse));
                SetPurCompaniesHouseCharge(parseInt(prices[0].companiesHouseCharge));
                setPurLandSearchCompany(parseInt(prices[0].landSearchCompany));
            }
            if(purEnteredPrice < 80001) {
                purIfNewBuild === true ? (
                    setPurHmLandRegFee(parseInt(prices[0].landRegFee1NB))
                ) : (
                    setPurHmLandRegFee(parseInt(prices[0].landRegFee1))
                )
            } else if(purEnteredPrice > 80000 && purEnteredPrice < 100001) {
                purIfNewBuild === true ? (
                    setPurHmLandRegFee(parseInt(prices[0].landRegFee2NB))
                ) : (
                    setPurHmLandRegFee(parseInt(prices[0].landRegFee2))
                )
            } else if(purEnteredPrice > 100000 && purEnteredPrice < 200001) {
                purIfNewBuild === true ? (
                    setPurHmLandRegFee(parseInt(prices[0].landRegFee3NB))
                ) : (
                    setPurHmLandRegFee(parseInt(prices[0].landRegFee3))
                )
            } else if(purEnteredPrice > 200000 && purEnteredPrice < 500001) {
                purIfNewBuild === true ? (
                    setPurHmLandRegFee(parseInt(prices[0].landRegFee4NB))
                ) : (
                    setPurHmLandRegFee(parseInt(prices[0].landRegFee4))
                )
            } else if(purEnteredPrice > 500000 && purEnteredPrice < 1000001) {
                purIfNewBuild === true ? (
                    setPurHmLandRegFee(parseInt(prices[0].landRegFee5NB))
                ) : (
                    setPurHmLandRegFee(parseInt(prices[0].landRegFee5))
                )
            } else if(purEnteredPrice > 1000000) {
                purIfNewBuild === true ? (
                    setPurHmLandRegFee(parseInt(prices[0].landRegFee6NB))
                ) : (
                    setPurHmLandRegFee(parseInt(prices[0].landRegFee6))
                )
            }
            //Base Fee Calculation
            if(purEnteredPrice < 150001) {
                setPurLegalFee(parseInt(prices[0].legalFee1));
            } else if(purEnteredPrice > 150000 && purEnteredPrice < 250001) {
                setPurLegalFee(parseInt(prices[0].legalFee2));
            } else if(purEnteredPrice > 250000 && purEnteredPrice < 500001) {
                setPurLegalFee(parseInt(prices[0].legalFee3));
            } else if(purEnteredPrice > 500000 && purEnteredPrice < 750001) {
                setPurLegalFee(parseInt(prices[0].legalFee4));
            } else if(purEnteredPrice > 750000 && purEnteredPrice < 1000001) {
                setPurLegalFee(parseInt(prices[0].legalFee5));
            } else if(purEnteredPrice > 1000000 && purEnteredPrice < 2000001) {
                setPurLegalFee(parseInt(prices[0].legalFee6));
            } else if(purEnteredPrice > 2000000) {
                setPurLegalFee(parseInt(Number(purEnteredPrice) * 0.10 / 100) );
            }
            setLoading(false);
        }
    };

    const calculatePurTotal = () => {
        if(purLegalFee > 0) {
            setLoading(true);
            setPurTotalQuote(purLegalFee + purIdCheck + purLawyerChecker + purTtFee + purStampDutyLandTax + purStampDuty + purLeaseholdFlat + purLeaseholdHouse + purNewBuild + purSharedOwner + purUnregisteredProperty + purAuctionSalePur + purBridgingFinance + purSpecialLender + purGifted + purCompaniesHouse + purSearches + purCompaniesHouseCharge + purLandSearch + purLandSearchCompany + purHmLandRegFee);
            
            setPurTotalQuoteWithoutStampDuty(purLegalFee + purIdCheck + purLawyerChecker + purTtFee + purStampDutyLandTax + purLeaseholdFlat + purLeaseholdHouse + purNewBuild + purSharedOwner + purUnregisteredProperty + purAuctionSalePur + purBridgingFinance + purSpecialLender + purGifted + purCompaniesHouse + purSearches + purCompaniesHouseCharge +  purLandSearch + purLandSearchCompany + purHmLandRegFee);
            setLoading(false);
        }
    }

    useEffect(() => {
        const loadAsyncStuff = async () => {
            try {
                const response = await fetch('https://api.propertydata.co.uk/stamp-duty-calculator?key=FVDWI5SWRS&value=' + purEnteredPrice);
                const json = await response.json();
                setPurStampDuty(json.transaction_tax_payable);
            } catch (error) {
                console.log(error);
            } finally {
                console.log(true);
            }
            };
            loadAsyncStuff();
    }, []);
    
    useEffect(() => {
        getPrices();
    }, [purStampDuty]);

    useEffect(() => {
        saleFeeCalculations();
        purFeeCalculations();
    }, [prices]);

    useEffect(() => {
        calculateSaleTotal();
        calculatePurTotal();
    }, [purLegalFee]);

    // Create styles
    const styles = StyleSheet.create({
        page: {
            backgroundColor: "#fff",
            color: "#000",
            padding: 50,
        },
        title: {
            fontSize: 16,
            padding:10,
            textDecoration: "underline",
            width: '100%',
            textAlign: 'center',
        },
        text: {
            fontSize: 12,
            padding:10,
            textAlign: 'left',
            display: 'flex',
            alignContent: 'center',
        },
        section: {
            margin: 10,
            padding: 10,
        },
        viewer: {
            width: window.innerWidth,
            height: window.innerHeight,
        },
        table: { 
            display: "table", 
            width: "auto", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderRightWidth: 1, 
            borderBottomWidth: 1,
            borderTopWidth: 1,
            borderLeftWidth: 1,
            width: "80%"
        }, 
        tableRow: { 
            margin: "auto", 
            flexDirection: "row",
        },
        tableRowTopMargin: { 
            margin: "10px auto auto auto", 
            flexDirection: "row",
            borderTop: 1,
            backgroundColor: '#cccccc',
        },
        tableRowFirstLine: { 
            margin: "auto", 
            flexDirection: "row",
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderRightWidth: 0, 
            borderBottomWidth: 0, 
            borderTopWidth: 1,
            backgroundColor: '#000',
            color: '#fff',
        },
        tableColFullWidth: { 
            width: '100%',
            margin: "0", 
            flexDirection: "row",
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderRightWidth: 0, 
            borderBottomWidth: 0, 
            borderTopWidth: 1,
            backgroundColor: '#000',
            color: '#fff',
            backgroundColor: '#333333',
        },
        tableColfirst: { 
            width: "50%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 1, 
            borderTopWidth: 0 ,
        },
        tableColGreyFirst: {
            width: "50%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 1, 
            borderTopWidth: 0 ,
            color: '#fff',
            backgroundColor: '#cccccc',
        },
        tableColGrey: {
            width: "25%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 1, 
            borderTopWidth: 0 ,
            color: '#fff',
            backgroundColor: '#cccccc',
        },
        tableColfirstDark: {
            width: "50%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 1, 
            borderTopWidth: 0 ,
            color: '#fff',
            backgroundColor: '#333333',
        },
        tableCol: { 
            width: "25%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0 
        },
        tableCol50: {
            width: "50%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0 
        },
        tableCell: { 
            margin: "auto", 
            marginTop: 5, 
            fontSize: 10 
        },
        container: {
            padding: 10,
        },
        logo: {
            width: 200,
            padding: 10,
        }
    });
    const MyDoc = () => (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style="styles.section">
                    <Image src="/images/logo.png" style={styles.logo} />
                    <Text style={styles.title}>Conveyancing Estimate</Text>
                    <Text style={styles.text}>Thank you for your interest in our legal services. Please find below an Estimate for fees and disbursements in connection with your transaction at £{Math.round(saleTotalQuote+saleTotalQuote*20/100 + purTotalQuoteWithoutStampDuty + purTotalQuoteWithoutStampDuty*20/100 - parseInt(saleDiscount+saleDiscount*20/100) - parseInt(purDiscount+purDiscount*20/100) - parseInt(purHmLandRegFee*20/100))}</Text>
                    <Text style={styles.tableHeading}>Purchase Quote</Text>
                    <View style={styles.container}>
                        <View style={styles.tableRowFirstLine}>
                            <View style={styles.tableColfirst}>
                                <Text style={styles.tableCell}>Service</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Fee Exc. Vat</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Vat</Text> 
                            </View> 
                        </View>
                        <View style={styles.tableRowFirstLine}>
                            <View style={styles.tableColFullWidth}>
                                <Text style={styles.tableCell}>Legal fee</Text> 
                            </View> 
                        </View>
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>Legal Fee</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£{purLegalFee}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£{purLegalFee * 20 /100}</Text> 
                            </View>
                        </View>
                        <View style={styles.tableRowFirstLine}>
                            <View style={styles.tableColFullWidth}>
                                <Text style={styles.tableCell}>Additional costs added to base legal fee</Text> 
                            </View> 
                        </View>
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>ID Check</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>£{purIdCheck}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£{purIdCheck * 20 /100}</Text> 
                            </View>
                        </View>
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>Lawyer Checker Fee</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>£{purLawyerChecker}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£{purLawyerChecker * 20 /100}</Text> 
                            </View>
                        </View>
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>TT Fee</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>£{purTtFee}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£{purTtFee * 20 /100}</Text> 
                            </View>
                        </View>
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>Stamp Duty Land Tax Form</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>£{purStampDutyLandTax}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£{purStampDutyLandTax * 20 /100}</Text> 
                            </View>
                        </View>
                        {props.purchaseFormData.propertyType === 'Flat' && props.purchaseFormData.propertyHold === 'Leasehold' ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Leasehold flat/apartment</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{purLeaseholdFlat}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{purLeaseholdFlat * 20 /100}</Text> 
                                </View>
                            </View>
                        ) : props.purchaseFormData.propertyType === 'House' && props.purchaseFormData.propertyHold === 'Leasehold' ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Leasehold house</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{purLeaseholdHouse}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{purLeaseholdHouse * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {purNewBuild > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>New build property</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{purNewBuild}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{purNewBuild * 20 /100}</Text> 
                                </View>
                            </View>
                        ):null }
                        {purSharedOwner > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Shared ownership</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{purSharedOwner}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{purSharedOwner * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {purSpecialLender > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Special Lender Fee</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{purSpecialLender}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{purSpecialLender * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {purGifted > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Gifted Property Fee</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{purGifted}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{purGifted * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {purUnregisteredProperty > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Unregistered property fee</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{purUnregisteredProperty}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{purUnregisteredProperty * 20 /100}</Text> 
                                </View>
                            </View>
                        ):null }
                        {purAuctionSalePur > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Auction sale / purchase</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{purAuctionSalePur}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{purAuctionSalePur * 20 /100}</Text> 
                                </View>
                            </View>
                        ):null }
                        {purBridgingFinance > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Bridging finance</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{purBridgingFinance}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{purBridgingFinance * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {purCompaniesHouse > 0 ?(
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Dealing with Limited Company</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{purCompaniesHouse}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{purCompaniesHouse * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {purCompaniesHouseCharge > 0 ?(
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Companies House charge (per charge)</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{purCompaniesHouseCharge}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{purCompaniesHouseCharge * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {purLandSearchCompany > 0 ? (  
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Land Registry & Bankruptcy searches (company)</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{purLandSearchCompany}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{purLandSearchCompany * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>Land Registry & Bankruptcy searches</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>£{purLandSearch}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£{purLandSearch * 20 /100}</Text> 
                            </View>
                        </View>
                        <View style={styles.tableRowFirstLine}>
                            <View style={styles.tableColFullWidth}>
                                <Text style={styles.tableCell}>Third party disbursements</Text> 
                            </View> 
                        </View>
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>Search Fee</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>£{purSearches}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£0</Text> 
                            </View>
                        </View>
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>HM Land Registration fee</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>£{purHmLandRegFee}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£0</Text> 
                            </View>
                        </View>
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>Stamp Duty</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>£{purStampDuty}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£0</Text> 
                            </View>
                        </View>
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>Total costs including VAT and disbursements</Text> 
                            </View> 
                            <View style={styles.tableCol50}>
                                <Text style={styles.tableCell}>£{Math.round(purTotalQuoteWithoutStampDuty + purTotalQuoteWithoutStampDuty*20/100 - parseInt(purHmLandRegFee*20/100) - parseInt(purSearches*20/100))}</Text> 
                            </View>
                        </View>
                    </View>

                    {/* Sale Table */}
                    <Text style={styles.tableHeading}>Sale Quote</Text>
                    <View style={styles.container}>
                        <View style={styles.tableRowFirstLine}>
                            <View style={styles.tableColfirst}>
                                <Text style={styles.tableCell}>Service</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Fee Exc. Vat</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Vat</Text> 
                            </View> 
                        </View>
                        <View style={styles.tableRowFirstLine}>
                            <View style={styles.tableColFullWidth}>
                                <Text style={styles.tableCell}>Legal fee</Text> 
                            </View> 
                        </View>
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>Legal Fee</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>£{saleLegalFee}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£{saleLegalFee * 20 /100}</Text> 
                            </View>
                        </View>
                        <View style={styles.tableRowFirstLine}>
                            <View style={styles.tableColFullWidth}>
                                <Text style={styles.tableCell}>Additional costs added to base legal fee</Text> 
                            </View> 
                        </View>
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>ID Check</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>£{saleIdCheck}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£{saleIdCheck * 20 /100}</Text> 
                            </View>
                        </View>
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>Lawyer Checker Fee</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>£{saleLawyerChecker}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£{saleLawyerChecker * 20 /100}</Text> 
                            </View>
                        </View>
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>TT Fee</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>£{saleTtFee}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£{saleTtFee * 20 /100}</Text> 
                            </View>
                        </View>
                        {props.saleFormData.propertyType === 'Flat' && props.saleFormData.propertyHold === 'Leasehold' ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Leasehold flat/apartment</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{saleLeaseholdFlat}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{saleLeaseholdFlat * 20 /100}</Text> 
                                </View>
                            </View>
                        ) : props.saleFormData.propertyType === 'House' && props.saleFormData.propertyHold === 'Leasehold' ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Leasehold house</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{saleLeaseholdHouse}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{saleLeaseholdHouse * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {saleSharedOwner > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Shared ownership</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{saleSharedOwner}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{saleSharedOwner * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {saleSpecialLender > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Special Lender Fee</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{saleSpecialLender}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{saleSpecialLender * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {saleProbate > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Probate Fee</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{saleProbate}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{saleProbate * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {saleAuctionSalePur > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Auction sale / purchase</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{saleAuctionSalePur}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{saleAuctionSalePur * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null }
                        {saleBridgingFinance > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Bridging finance</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{saleBridgingFinance}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{saleBridgingFinance * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {saleCompaniesHouse > 0 ?(
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Dealing with Limited Company</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{saleCompaniesHouse}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{saleCompaniesHouse * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {saleCompaniesHouseCharge > 0 ?(
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Companies House charge (per charge)</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{saleCompaniesHouseCharge}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{saleCompaniesHouseCharge * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {saleLandSearchCompany > 0 ? (  
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Land Registry & Bankruptcy searches (company)</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{saleLandSearchCompany}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{saleLandSearchCompany * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>Land Registry & Bankruptcy searches</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>£{saleLandSearch}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£{saleLandSearch * 20 /100}</Text> 
                            </View>
                        </View>
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>Total costs including VAT and disbursements</Text> 
                            </View> 
                            <View style={styles.tableCol50}>
                                <Text style={styles.tableCell}>£{Math.round(saleTotalQuote + saleTotalQuote*20/100)}</Text> 
                            </View>
                        </View>
                        {saleDiscount > 0 ? (
                            <>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableColGreyFirst}> 
                                        <Text style={styles.tableCell}>Discount</Text> 
                                    </View> 
                                    <View style={styles.tableColGrey}> 
                                        <Text style={styles.tableCell}>£{saleDiscount}</Text> 
                                    </View> 
                                    <View style={styles.tableColGrey}>
                                        <Text style={styles.tableCell}>£{saleDiscount * 20 /100}</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableColfirst}>
                                        <Text style={styles.tableCell}>Total After Discount</Text> 
                                    </View>
                                    <View style={styles.tableCol50}>
                                        <Text style={styles.tableCell}>£{Math.round(parseInt(parseInt(purTotalQuoteWithoutStampDuty + saleTotalQuote)-saleDiscount) + parseInt(parseInt(purTotalQuoteWithoutStampDuty + saleTotalQuote)-saleDiscount)*20/100)-parseInt(purHmLandRegFee*20/100) - parseInt(purSearches*20/100)}</Text> 
                                    </View>
                                    
                                </View>
                            </>
                        ) :null}
                    </View>
                {/* Sale Table */}

                    <Text style={styles.text}>
                        At Wilford Smith Solicitors, we pride ourselves on customer service. We will only ever be at the end of the phone or email if you ever have any queries.
                    </Text>
                    <Text style={styles.text}>
                        If you would like to instruct us to proceed, please reply by return of email or call us on 0808 1695677:
                    </Text>
                    <Text style={styles.text}>
                        ** The fees and disbursement, where applicable, are inclusive of VAT at current rates and that should the VAT 
                        rate change the estimate will be subject to change. 
                    </Text>
                    <Text style={styles.text}>
                        The estimate is based on the information available at the time the estimate was given but additional charges and disbursements, and VAT where appropriate, may apply depending on the nature of the transaction.
                    </Text>
                    <Text style={styles.text}>
                        Subject to the above this estimate will be valid until the expiry of 28 days the starting date for which will be the day after the estimate was given.
                    </Text>
                </View>
            </Page>
        </Document>
    );

    return (
        <div>
            <div className="display-6 text-start">Your Estimate</div>
            <div className="quote_big">
                £{Math.round(saleTotalQuote+saleTotalQuote*20/100 + purTotalQuoteWithoutStampDuty + purTotalQuoteWithoutStampDuty*20/100 - parseInt(purDiscount+purDiscount*20/100) - parseInt(saleDiscount+saleDiscount*20/100) - parseInt(purHmLandRegFee*20/100))}
            </div>
            <p className="quote_subtext">
                This includes all your legal fees, disbursements & VAT.
                <br></br>
                Excludes Stamp Duty.
            </p>

            {/* Purchase Table */}
            <table className="table">
                <thead>
                    <tr>
                        <th>Purchase Quote</th>
                        <th>Exc. VAT</th>
                        <th>VAT</th>
                        <th>Inc. VAT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="table-primary">
                        <td colSpan="4">Legal Fees</td>
                    </tr>
                    <tr className="fee">
                        <td>Legal Fees</td>
                        <td>£{purLegalFee}</td>
                        <td>£{purLegalFee * 20 /100}</td>
                        <td>£{purLegalFee + purLegalFee*20/100}</td>
                    </tr>
                    <tr className="table-primary">
                        <td colSpan="4">Additional costs added to base legal fee</td>
                    </tr>
                    <tr className="fee">
                        <td>ID check</td>
                        <td>£{purIdCheck}</td>
                        <td>£{purIdCheck*20/100}</td>
                        <td>£{purIdCheck + purIdCheck*20/100}</td>
                    </tr>
                    <tr className="fee">
                        <td>Lawyer Checker fee</td>
                        <td>£{purLawyerChecker}</td>
                        <td>£{purLawyerChecker*20/100}</td>
                        <td>£{purLawyerChecker + purLawyerChecker*20/100}</td>
                    </tr>
                    <tr className="fee">
                        <td>TT fee (per payment)</td>
                        <td>£{purTtFee}</td>
                        <td>£{purTtFee*20/100}</td>
                        <td>£{purTtFee + purTtFee*20/100}</td>
                    </tr>
                    {purStampDutyLandTax > 0 ? (
                    <tr className="fee">
                        <td>Stamp Duty Land Tax Form</td>
                        <td>£{purStampDutyLandTax}</td>
                        <td>£{purStampDutyLandTax*20/100}</td>
                        <td>£{purStampDutyLandTax + purStampDutyLandTax*20/100}</td>
                    </tr>
                    ) :null}
                    {props.purchaseFormData.propertyType === 'Flat' && props.purchaseFormData.propertyHold === 'Leasehold' ? (
                        <tr className="fee">
                            <td>Leasehold flat/apartment</td>
                            <td>£{purLeaseholdFlat}</td>
                            <td>£{purLeaseholdFlat*20/100}</td>
                            <td>£{purLeaseholdFlat + purLeaseholdFlat*20/100}</td>
                        </tr>
                    ) : props.purchaseFormData.propertyType === 'House' && props.purchaseFormData.propertyHold === 'Leasehold' ? (
                        <tr className="fee">
                            <td>Leasehold house</td>
                            <td>£{purLeaseholdHouse}</td>
                            <td>£{purLeaseholdHouse*20/100}</td>
                            <td>£{purLeaseholdHouse + purLeaseholdHouse*20/100}</td>
                        </tr>
                    ) :null}
                    {purNewBuild > 0 ? (
                        <tr className="fee">
                            <td>New build property</td>
                            <td>£{purNewBuild}</td>
                            <td>£{purNewBuild*20/100}</td>
                            <td>£{purNewBuild + purNewBuild*20/100}</td>
                        </tr>
                    ):null }
                    {purSharedOwner > 0 ? (
                    <tr className="fee">
                        <td>Shared ownership</td>
                        <td>£{purSharedOwner}</td>
                        <td>£{purSharedOwner*20/100}</td>
                        <td>£{purSharedOwner + purSharedOwner*20/100}</td>
                    </tr>
                    ):null }
                    {purSpecialLender > 0 ? (
                    <tr className="fee">
                        <td>Special Lender Fee</td>
                        <td>£{purSpecialLender}</td>
                        <td>£{purSpecialLender*20/100}</td>
                        <td>£{purSpecialLender + purSpecialLender*20/100}</td>
                    </tr>
                    ):null }
                    {purGifted > 0 ? (
                        <tr className="fee">
                            <td>Gifted Property Fee</td>
                            <td>£{purGifted}</td>
                            <td>£{purGifted*20/100}</td>
                            <td>£{purGifted + purGifted*20/100}</td>
                        </tr>
                    ) :null}
                    {purUnregisteredProperty > 0 ?(
                        <tr className="fee">
                            <td>Unregistered property fee</td>
                            <td>£{purUnregisteredProperty}</td>
                            <td>£{purUnregisteredProperty*20/100}</td>
                            <td>£{purUnregisteredProperty + purUnregisteredProperty*20/100}</td>
                        </tr>
                    ):null }

                    {props.purchaseAuctionInfo[2].checked === true ? (
                        <tr className="fee">
                            <td>Auction sale / purchase</td>
                            <td>£{purAuctionSalePur}</td>
                            <td>£{purAuctionSalePur*20/100}</td>
                            <td>£{purAuctionSalePur + purAuctionSalePur*20/100}</td>
                        </tr>
                    ):null }
                    {purBridgingFinance > 0 ? (
                        <tr className="fee">
                            <td>Bridging finance</td>
                            <td>£{purBridgingFinance}</td>
                            <td>£{purBridgingFinance*20/100}</td>
                            <td>£{purBridgingFinance + purBridgingFinance*20/100}</td>
                        </tr>
                    ) :null}
                    {purCompaniesHouse > 0 ?(
                        <tr className="fee">
                            <td>Dealing with Limited Company</td>
                            <td>£{purCompaniesHouse}</td>
                            <td>£{purCompaniesHouse*20/100}</td>
                            <td>£{purCompaniesHouse + purCompaniesHouse*20/100}</td>
                        </tr>
                    ) :null}
                    {purCompaniesHouseCharge > 0 ?(
                        <tr className="fee">
                            <td>Companies House charge (per charge)</td>
                            <td>£{purCompaniesHouseCharge}</td>
                            <td>£{purCompaniesHouseCharge*20/100}</td>
                            <td>£{purCompaniesHouseCharge + purCompaniesHouseCharge*20/100}</td>
                        </tr>
                    ) :null}
                    <tr className="fee">
                        <td>Search Fee</td>
                        <td>£{purSearches}</td>
                        <td>£0</td>
                        <td>£{purSearches}</td>
                    </tr>
                    {purLandSearchCompany > 0 ? (  
                        <tr className="fee">
                            <td>Land Registry & Bankruptcy searches (company)</td>
                            <td>£{purLandSearchCompany}</td>
                            <td>£{purLandSearchCompany*20/100}</td>
                            <td>£{purLandSearchCompany + purLandSearchCompany*20/100}</td>
                        </tr>
                    ) :null}
                    <tr className="fee">
                        <td>Land Registry & Bankruptcy searches</td>
                        <td>£{purLandSearch}</td>
                        <td>£{purLandSearch*20/100}</td>
                        <td>£{purLandSearch + purLandSearch*20/100}</td>
                    </tr>
                    {purStampDuty > 0 ?(
                    <tr className="fee">
                        <td>Stamp Duty</td>
                        <td>£{purStampDuty}</td>
                        <td>0</td>
                        <td>£{purStampDuty}</td>
                    </tr>
                    ): null }
                    <tr className="table-primary">
                        <td colSpan="4">Third party disbursements</td>
                    </tr>
                    <tr className="fee">
                        <td>HM Land Registration fee</td>
                        <td>£{purHmLandRegFee}</td>
                        <td>£0</td>
                        <td>£{purHmLandRegFee}</td>
                    </tr>
                    <tr className="table-success">
                        <td>Total</td>
                        <td>£{purTotalQuoteWithoutStampDuty}</td>
                        <td>£{purTotalQuoteWithoutStampDuty*20/100 - parseInt(purHmLandRegFee*20/100) - parseInt(purSearches*20/100)}</td>
                        <td>£{Math.round(purTotalQuoteWithoutStampDuty + purTotalQuoteWithoutStampDuty*20/100 - parseInt(purHmLandRegFee*20/100) - parseInt(purSearches*20/100))}</td>
                    </tr>
                </tbody>
            </table>

            {/* Sale Table */}
            <table className="table  margin-top">
                <thead>
                    <tr>
                        <th>Sale Quote</th>
                        <th>Exc. VAT</th>
                        <th>VAT</th>
                        <th>Inc. VAT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="table-primary">
                        <td colSpan="4">Legal Fees</td>
                    </tr>
                    <tr className="fee">
                        <td>Legal Fees</td>
                        <td>£{saleLegalFee}</td>
                        <td>£{saleLegalFee * 20 /100}</td>
                        <td>£{saleLegalFee + saleLegalFee*20/100}</td>
                    </tr>
                    <tr className="table-primary">
                        <td colSpan="4">Additional costs added to base legal fee</td>
                    </tr>
                    <tr className="fee">
                        <td>ID check</td>
                        <td>£{saleIdCheck}</td>
                        <td>£{saleIdCheck*20/100}</td>
                        <td>£{saleIdCheck + saleIdCheck*20/100}</td>
                    </tr>
                    <tr className="fee">
                        <td>Lawyer Checker fee</td>
                        <td>£{saleLawyerChecker}</td>
                        <td>£{saleLawyerChecker*20/100}</td>
                        <td>£{saleLawyerChecker + saleLawyerChecker*20/100}</td>
                    </tr>
                    <tr className="fee">
                        <td>TT fee (per payment)</td>
                        <td>£{saleTtFee}</td>
                        <td>£{saleTtFee*20/100}</td>
                        <td>£{saleTtFee + saleTtFee*20/100}</td>
                    </tr>
                    {props.saleFormData.propertyType === 'Flat' && props.saleFormData.propertyHold === 'Leasehold' ? (
                        <tr className="fee">
                            <td>Leasehold flat/apartment</td>
                            <td>£{saleLeaseholdFlat}</td>
                            <td>£{saleLeaseholdFlat*20/100}</td>
                            <td>£{saleLeaseholdFlat + saleLeaseholdFlat*20/100}</td>
                        </tr>
                    ) : props.saleFormData.propertyType === 'House' && props.saleFormData.propertyHold === 'Leasehold' ? (
                        <tr className="fee">
                            <td>Leasehold house</td>
                            <td>£{saleLeaseholdHouse}</td>
                            <td>£{saleLeaseholdHouse*20/100}</td>
                            <td>£{saleLeaseholdHouse + saleLeaseholdHouse*20/100}</td>
                        </tr>
                    ) :null}
                    {saleSharedOwner > 0 ? (
                    <tr className="fee">
                        <td>Shared ownership</td>
                        <td>£{saleSharedOwner}</td>
                        <td>£{saleSharedOwner*20/100}</td>
                        <td>£{saleSharedOwner + saleSharedOwner*20/100}</td>
                    </tr>
                    ) :null}
                    {saleSpecialLender > 0 ? (
                    <tr className="fee">
                        <td>Special Lender Fee</td>
                        <td>£{saleSpecialLender}</td>
                        <td>£{saleSpecialLender*20/100}</td>
                        <td>£{saleSpecialLender + saleSpecialLender*20/100}</td>
                    </tr>
                    ):null }
                    {saleProbate > 0 ? (
                        <tr className="fee">
                            <td>Probate Fee</td>
                            <td>£{saleProbate}</td>
                            <td>£{saleProbate*20/100}</td>
                            <td>£{saleProbate + saleProbate*20/100}</td>
                        </tr>
                    ) :null}
                    {saleAuctionSalePur > 0 ? (
                        <tr className="fee">
                            <td>Auction sale / purchase</td>
                            <td>£{saleAuctionSalePur}</td>
                            <td>£{saleAuctionSalePur*20/100}</td>
                            <td>£{saleAuctionSalePur + saleAuctionSalePur*20/100}</td>
                        </tr>
                    ) : null }
                    {saleBridgingFinance > 0 ? (
                        <tr className="fee">
                            <td>Bridging finance</td>
                            <td>£{saleBridgingFinance}</td>
                            <td>£{saleBridgingFinance*20/100}</td>
                            <td>£{saleBridgingFinance + saleBridgingFinance*20/100}</td>
                        </tr>
                    ) :null}
                    {saleCompaniesHouse > 0 ?(
                        <tr className="fee">
                            <td>Dealing with Limited Company</td>
                            <td>£{saleCompaniesHouse}</td>
                            <td>£{saleCompaniesHouse*20/100}</td>
                            <td>£{saleCompaniesHouse + saleCompaniesHouse*20/100}</td>
                        </tr>
                    ) :null}
                    {saleCompaniesHouseCharge > 0 ?(
                        <tr className="fee">
                            <td>Companies House charge (per charge)</td>
                            <td>£{saleCompaniesHouseCharge}</td>
                            <td>£{saleCompaniesHouseCharge*20/100}</td>
                            <td>£{saleCompaniesHouseCharge + saleCompaniesHouseCharge*20/100}</td>
                        </tr>
                    ) :null}
                    {saleLandSearchCompany > 0 ? (  
                        <tr className="fee">
                            <td>Land Registry & Bankruptcy searches (company)</td>
                            <td>£{saleLandSearchCompany}</td>
                            <td>£{saleLandSearchCompany*20/100}</td>
                            <td>£{saleLandSearchCompany + saleLandSearchCompany*20/100}</td>
                        </tr>
                    ) :null}
                    <tr className="fee">
                        <td>Land Registry & Bankruptcy searches</td>
                        <td>£{saleLandSearch}</td>
                        <td>£{saleLandSearch*20/100}</td>
                        <td>£{saleLandSearch + saleLandSearch*20/100}</td>
                    </tr>
                    <tr className="table-success">
                        <td>Total</td>
                        <td>£{saleTotalQuote}</td>
                        <td>£{saleTotalQuote*20/100}</td>
                        <td>£{saleTotalQuote + saleTotalQuote*20/100}</td>
                    </tr>
                    {saleDiscount > 0 ? (
                        <>
                            <tr className="table-secondary">
                                <td>Discount</td>
                                <td>£{saleDiscount}</td>
                                <td>£{saleDiscount*20/100}</td>
                                <td>£{saleDiscount + saleDiscount*20/100}</td>
                            </tr>
                            <tr className="table-success">
                                <td>Total After Discount</td>
                                <td>£{parseInt(purTotalQuoteWithoutStampDuty + saleTotalQuote) - saleDiscount}</td>
                                <td>£{parseInt(parseInt(purTotalQuoteWithoutStampDuty + saleTotalQuote)-saleDiscount)*20/100 - parseInt(purHmLandRegFee*20/100)}</td>
                                <td>£{Math.round(parseInt(parseInt(purTotalQuoteWithoutStampDuty + saleTotalQuote)-saleDiscount) + parseInt(parseInt(purTotalQuoteWithoutStampDuty + saleTotalQuote)-saleDiscount)*20/100)-parseInt(purHmLandRegFee*20/100)}</td>
                            </tr>
                        </>
                    ) :null}
                </tbody>
            </table>

            <div className="quote_actions">
                <button onClick={instructClickHandler} className="btn btn-dark mx-2">Instruct Quote</button>
                <PDFDownloadLink document={<MyDoc />} fileName="quote.pdf" className="print_icon">
                    {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : <FontAwesomeIcon icon={faPrint} />
                    }
                </PDFDownloadLink>
                <BlobProvider document={<MyDoc />}>
                    {({ blob, url, loading, error }) => {
                        const pdf2base64 = require('pdf-to-base64');
                            pdf2base64(url, {type: "application/pdf"})
                                .then(
                                    (response) => {
                                        // setPdfCode(response)
                                        pdfbase = response
                                    }
                                )
                                .catch(
                                    (error) => {
                                        console.log(error); //Exepection error....
                                    }
                                )
                    }}
                </BlobProvider>
            </div>
            {loading === true ? (
                <div className="loading">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ): null}
        </div>
    )
}

export default SalePurchaseQuote;