import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Fragment } from "react";

const SalePurchaseForm = (props) => {
    const formSubmitHandler = (e) => {
        e.preventDefault();
        props.formSubmitHandler(e);
    }
    const auctionChangeHandler = (e) => {
        props.auctionChangeHandler(e);
    };
    const haveMortgageHandler = (e) => {
        props.haveMortgageHandler(e);
    }
    const propertyHoldHandler = (e) => {
        props.propertyHoldHandler(e);
    }
    const propertyTypeHandler = (e) => {
        props.propertyTypeHandler(e);
    }
    const addressKnownHandler = (e) => {
        props.addressKnownHandler(e);
    }
    const propertygiftedHandler = (e) => {
        props.propertygiftedHandler(e);
    }
    const firstTimeHandler = (e) => {
        props.firstTimeHandler(e);
    }
    const purchaseAdditionalHandler = (e) => {
        props.purchaseAdditionalHandler(e);
    }
    const bridgingFinanceHandler = (e) => {
        props.bridgingFinanceHandler(e);
    }
    const usingCompanyHandler = (e) => {
        props.usingCompanyHandler(e);
    }
    const directorIncrementHandler = (e) => {
        e.preventDefault();
        props.directorIncrementHandler(e);
    }
    const directorNameHandler = (e, index) => {
        props.directorNameHandler(e, index);
    }
    const directorPhoneHandler = (e, index) => {
        props.directorPhoneHandler(e, index);
    }
    const directorEmailHandler = (e, index) => {
        props.directorEmailHandler(e, index);
    }
    const addressPostcodeHandler = (e) => {
        props.addressPostcodeHandler(e);
    }
    return (
        <form className="needs-validation pt-4" onSubmit={formSubmitHandler}>
            {/* Purchase Form */}
            <div>
                <div class="display-6 text-start">About the Purchase</div>
                <div className="row pt-2 media-1">
                    <div className="col-4 media-width">
                        <label for="pur_price" className="text-start formLabel">Purchase Price</label>
                    </div>
                    <div className="col input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">£</span>
                        </div>
                        <input type="number" min='1000' className="col form-control" id="purchasePrice" required />
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="price" className="text-start formLabel">Will you be getting a mortgage?</label>
                    </div>
                    <div className="col radio_btns">
                        <input className="form-check-input" type="radio" id="have_mortgage21" name="pur_have_mortgage" value="No" onChange={haveMortgageHandler} checked={props.purchaseFormData.gettingMortgage === 'No'} />
                        <label className="form-check-label" for="have_mortgage21">
                            No
                        </label>
                        <input className="form-check-input" type="radio" name="pur_have_mortgage" value="Yes" id="have_mortgage22" onChange={haveMortgageHandler} />
                        <label className="form-check-label" for="have_mortgage22">
                            Yes
                        </label>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="price" className="text-start formLabel">Are you using Bridging Finance?</label>
                    </div>
                    <div className="col t radio_btns">
                        <input className="form-check-input" type="radio" name="purchaseBridgingFinance" id="purchaseBridgingFinance1" value="No" onChange={bridgingFinanceHandler} checked={props.purchaseFormData.bridgingFinance === 'No'} />
                        <label className="form-check-label" for="purchaseBridgingFinance1">
                            No
                        </label>
                        <input className="form-check-input" type="radio" name="purchaseBridgingFinance" id="purchaseBridgingFinance2" value="Yes" onChange={bridgingFinanceHandler} />
                        <label className="form-check-label" for="purchaseBridgingFinance2">
                            Yes
                        </label>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="price" className="text-start formLabel">Who is your lender?</label>
                    </div>
                    <div className="col">
                        <select id="pur_mortgageLender" class="form-control">
                            <option value="-">Please select...</option>
                            <option value="Not Applicable">Not Applicable</option>
                            <option value="Unknown or unlisted lender">Unknown or unlisted lender</option>
                            <option value="Accord Mortgages">Accord Mortgages</option>
                            <option value="Aldemore">Aldemore</option>
                            <option value="Alliance & Leicester">Alliance & Leicester</option>
                            <option value="Atom Bank">Atom Bank</option>
                            <option value="Bank of Cyprus">Bank of Cyprus</option>
                            <option value="Bank of Ireland">Bank of Ireland</option>
                            <option value="Bank of Scotland">Bank of Scotland</option>
                            <option value="Barclays Bank UK Plc">Barclays Bank UK Plc</option>
                            <option value="Birmingham Midshires">Birmingham Midshires</option>
                            <option value="Bluestone Mortgages">Bluestone Mortgages</option>
                            <option value="BM Solutions">BM Solutions</option>
                            <option value="Britannia Buildings Society">Britannia Buildings Society</option>
                            <option value="Buckinghamshire Building Society">Buckinghamshire Building Society</option>
                            <option value="Cambridge Building Society">Cambridge Building Society</option>
                            <option value="Castle Trust Capital plc">Castle Trust Capital plc</option>
                            <option value="Cheltenham & Gloucester Building Society">Cheltenham & Gloucester Building Society</option>
                            <option value="Chelsea Building Society">Chelsea Building Society</option>
                            <option value="Chorley Building Society">Chorley Building Society</option>
                            <option value="Co-Operative Bank">Co-Operative Bank</option>
                            <option value="Cumberland Building Society">Cumberland Building Society</option>
                            <option value="Cynergy Bank">Cynergy Bank</option>
                            <option value="Danske Bank">Danske Bank</option>
                            <option value="Darlington Building Society">Darlington Building Society</option>
                            <option value="Dudley Building Society">Dudley Building Society</option>
                            <option value="Family Building Society">Family Building Society</option>
                            <option value="Foundation Home Loans">Foundation Home Loans</option>
                            <option value="Fleet Mortgages">Fleet Mortgages</option>
                            <option value="Guernsey Home Loans">Guernsey Home Loans</option>
                            <option value="Godiva Mortgages">Godiva Mortgages</option>
                            <option value="Halifax Building Society">Halifax Building Society</option>
                            <option value="Handels Banken">Handels Banken</option>
                            <option value="Help to Buy: ISA">Help to Buy: ISA</option>
                            <option value="Heritage Development Finance">Heritage Development Finance</option>
                            <option value="Hinckley and Rugby Building Society">Hinckley and Rugby Building Society</option>
                            <option value="HSBC">HSBC</option>
                            <option value="Interbay Commercial">Interbay Commercial</option>
                            <option value="Investec Bank">Investec Bank</option>
                            <option value="Ipswich Building Society">Ipswich Building Society</option>
                            <option value="Kensington Mortgages">Kensington Mortgages</option>
                            <option value="Jersey Home Loans">Jersey Home Loans</option>
                            <option value="Kensington Mortgages">Kensington Mortgages</option>
                            <option value="Kent Reliance">Kent Reliance</option>
                            <option value="Landbay - Buy to Let">Landbay - Buy to Let</option>
                            <option value="Leeds Building Society">Leeds Building Society</option>
                            <option value="Leek United Building Society">Leek United Building Society</option>
                            <option value="Lloyds Bank Group">Lloyds Bank Group</option>
                            <option value="Magellan Homeloans">Magellan Homeloans</option>
                            <option value="M&S Bank">M&S Bank</option>
                            <option value="Market Harborough Building Society">Market Harborough Building Society</option>
                            <option value="Marsden Building Society">Marsden Building Society</option>
                            <option value="Masthaven Bank">Masthaven Bank</option>
                            <option value="Melton Mowbray Building Society">Melton Mowbray Building Society</option>
                            <option value="Metro Bank">Metro Bank</option>
                            <option value="Monmouthshire Building Society">Monmouthshire Building Society</option>
                            <option value="National Counties Building Society & Family Building Society">National Counties Building Society & Family Building Society</option>
                            <option value="Nationwide Building Society">Nationwide Building Society</option>
                            <option value="Natwest">Natwest</option>
                            <option value="Newbury Building Society">Newbury Building Society</option>
                            <option value="Newcastle Building Society">Newcastle Building Society</option>
                            <option value="Nottingham Building Society">Nottingham Building Society</option>
                            <option value="One Savings Bank - Kent Reliance">One Savings Bank - Kent Reliance</option>
                            <option value="OSBIndia">OSBIndia</option>
                            <option value="Paragon Mortgages">Paragon Mortgages</option>
                            <option value="Platform - Co-operative Bank">Platform - Co-operative Bank</option>
                            <option value="Precise Mortgages">Precise Mortgages</option>
                            <option value="Prestige Finance">Prestige Finance</option>
                            <option value="Principality Building Society">Principality Building Society</option>
                            <option value="Sainsburys Bank">Sainsburys Bank</option>
                            <option value="Santander">Santander</option>
                            <option value="Scottish Building Society">Scottish Building Society</option>
                            <option value="Scottish Widows Bank">Scottish Widows Bank</option>
                            <option value="Secure Trust Bank">Secure Trust Bank</option>
                            <option value="Skipton Building Society">Skipton Building Society</option>
                            <option value="TSB Bank">Tesco Bank</option>
                            <option value="The Mortgage Lender">The Mortgage Lender</option>
                            <option value="The Mortgage Works">The Mortgage Works</option>
                            <option value="The Nottingham">The Nottingham</option>
                            <option value="The Paragon Group">The Paragon Group</option>
                            <option value="The Royal Bank of Scotland">The Royal Bank of Scotland</option>
                            <option value="The Woolwich">The Woolwich</option>
                            <option value="Tipton Coseley Building Society">Tipton Coseley Building Society</option>
                            <option value="Together">Together</option>
                            <option value="TSB Bank">TSB Bank</option>
                            <option value="TSB Lloyds Banking Group">TSB Lloyds Banking Group</option>
                            <option value="Virgin Money">Virgin Money</option>
                            <option value="West Bromich Building Society">West Bromich Building Society</option>
                            <option value="Yorkshire Building Society Group">Yorkshire Building Society Group</option>
                        </select>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="price" className="text-start formLabel">Freehold or leasehold property?</label>
                    </div>
                    <div className="col t radio_btns">
                        <input className="form-check-input" type="radio" name="pur_propertyHold" id="saleFreeorlease21" value="Freehold" onChange={propertyHoldHandler} checked={props.purchaseFormData.propertyHold === 'Freehold'} />
                        <label className="form-check-label" for="saleFreeorlease21">
                            Freehold
                        </label>
                        <input className="form-check-input" type="radio" name="pur_propertyHold" id="saleFreeorlease22" value="Leasehold" onChange={propertyHoldHandler} />
                        <label className="form-check-label" for="saleFreeorlease22">
                            Leasehold
                        </label>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="price" className="text-start formLabel">What type of property is it?</label>
                    </div>
                    <div className="col t radio_btns">
                        <input className="form-check-input" type="radio" name="pur_propertyType" id="saleProperty_type21" value="House" onChange={propertyTypeHandler} checked={props.purchaseFormData.propertyType === 'House'} />
                        <label className="form-check-label" for="saleProperty_type21">
                            House
                        </label>
                        <input className="form-check-input" type="radio" name="pur_propertyType" id="saleProperty_type22" value="Flat" onChange={propertyTypeHandler} />
                        <label className="form-check-label" for="saleProperty_type22">
                            Flat
                        </label>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="price" className="text-start formLabel">Where is the property located?</label>
                    </div>
                    <div className="col">
                        <select id="pur_propertyLocated" className="form-control">
                            <option value="England">England</option>
                            <option value="Wales">Wales</option>
                        </select>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="price" className="text-start formLabel">Number of Purchasers</label>
                    </div>
                    <div className="col">
                        <select id="pur_numberSeller" className="form-control">
                            <option value="1">Just Me</option>
                            <option value="1">Me and 1 other</option>
                            <option value="3">3 People</option>
                            <option value="4">4 People</option>
                        </select>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="purchaseSchemes" className="formLabel text-start">Are you using any of these schemes?</label>
                    </div>
                    <div className="col">
                        <select id="pur_purchaseSchemes" className="form-control">
                            <option value="No">No</option>
                            <option value="Help to Buy Equity Loan Scheme for new build properties">Help to Buy Equity Loan Scheme for new build properties</option>
                            <option value="Help to Buy mortgage scheme">Help to Buy mortgage scheme</option>
                            <option value="Shared Ownership Scheme">Shared Ownership Scheme</option>
                        </select>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="isAuction" className="formLabel text-start">Is the property any of the following?</label>
                    </div>
                    <div className="col checkboxes">
                        <div className="check">
                            <div className="checkbox-1">
                                <input className="form-check-input" type="checkbox" name="pur_isAuction" id="isAuction0" value="No" onChange={auctionChangeHandler} />
                                <label className="form-check-label" for="isAuction0">No</label>
                            </div>
                            <div className="checkbox-1">
                                <input className="form-check-input" type="checkbox" name="pur_isAuction" id="isAuction4" value="Newbuild" onChange={auctionChangeHandler} />
                                <label className="form-check-label" for="isAuction4">Newbuild</label>
                            </div>
                        </div>
                        <div className="check">
                            <div className="checkbox-1">
                                <input className="form-check-input" type="checkbox" name="pur_isAuction" id="isAuction1" value="Unregistered" onChange={auctionChangeHandler} />
                                <label className="form-check-label" for="isAuction1">Unregistered</label>
                            </div>
                            <div className="checkbox-1">
                                <input className="form-check-input" type="checkbox" name="pur_isAuction" id="isAuction2" value="Auction" onChange={auctionChangeHandler} />
                                <label className="form-check-label" for="isAuction2">Auction</label>
                            </div>
                        </div>
                        <div className="check">
                            <div className="checkbox-1">
                                <input className="form-check-input" type="checkbox" name="pur_isAuction" id="isAuction3" value="Buy to Let" onChange={auctionChangeHandler} />
                                <label className="form-check-label" for="isAuction3">Buy to Let</label>
                            </div>
                            <div className="checkbox-1">
                                <input className="form-check-input" type="checkbox" name="pur_isAuction" id="isAuction5" value="Repossession" onChange={auctionChangeHandler} />
                                <label className="form-check-label" for="isAuction5">Repossession</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label className="formLabel text-start">Are you purchasing this as an additional property?</label>
                    </div>
                    <div className="col t radio_btns">
                        <input className="form-check-input" type="radio" name="additionalProperty" id="additionalProperty1" value="No" onChange={purchaseAdditionalHandler} checked={props.purchaseFormData.purchasingAdditional === 'No'} />
                        <label className="form-check-label" for="additionalProperty1">
                            No
                        </label>
                        <input className="form-check-input" type="radio" name="additionalProperty" id="additionalProperty2" value="Yes" onChange={purchaseAdditionalHandler} />
                        <label className="form-check-label" for="additionalProperty2">
                            Yes
                        </label>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="price" className="text-start formLabel">Do you have any gifted deposit / gifted loans?</label>
                    </div>
                    <div className="col t radio_btns">
                        <input className="form-check-input" type="radio" name="giftDeposit" id="giftDeposit1" value="No" onChange={propertygiftedHandler} checked={props.purchaseFormData.gifted === 'No'} />
                        <label className="form-check-label" for="giftDeposit1">
                            No
                        </label>
                        <input className="form-check-input" type="radio" name="giftDeposit" id="giftDeposit2" value="Yes" onChange={propertygiftedHandler} />
                        <label className="form-check-label" for="giftDeposit2">
                            Yes
                        </label>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="price" className="text-start formLabel">Are you a first time buyer?</label>
                    </div>
                    <div className="col t radio_btns">
                        <input className="form-check-input" type="radio" name="firstBuyer" id="firstBuyer1" value="No" onChange={firstTimeHandler} checked={props.purchaseFormData.firstTimeBuyer === 'No'} />
                        <label className="form-check-label" for="firstBuyer1">
                            No
                        </label>
                        <input className="form-check-input" type="radio" name="firstBuyer" id="firstBuyer2" value="Yes" onChange={firstTimeHandler} />
                        <label className="form-check-label" for="firstBuyer2">
                            Yes
                        </label>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="price" className="text-start formLabel">Is the property address known?</label>
                    </div>
                    <div className="col t radio_btns">
                        <input className="form-check-input" type="radio" name="pur_addressKnown" id="saleAddress_known21" value="No" onChange={addressKnownHandler} checked={props.purchaseFormData.addressKnown === 'No'} />
                        <label className="form-check-label" for="saleAddress_known21">
                            No
                        </label>
                        <input className="form-check-input" type="radio" name="pur_addressKnown" id="saleAddress_known22" value="Yes" onChange={addressKnownHandler} />
                        <label className="form-check-label" for="saleAddress_known22">
                            Yes
                        </label>
                    </div>
                </div>
                {props.purchaseFormData.addressKnown === 'Yes' ? (
                    <div className="row pt-3 media-1">
                        <div className="col-4 media-width">
                            <label for="price" className="text-start formLabel">Property Address Postcode</label>
                        </div>
                        <div className="col t radio_btns">
                            <input type="text" className="form-control" aria-describedby="basic-addon1" id="purPropertyAddressPostcode" name="purPropertyAddressPostcode" required="required" defaultValue={props.purchaseFormData.propertyAddressPostcode} onChange={addressPostcodeHandler} />
                        </div>
                    </div>
                ) : null}
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="price" className="text-start formLabel">Are you using a Company for this transaction?</label>
                    </div>
                    <div className="col t radio_btns">
                        <input className="form-check-input" type="radio" name="usingCompany" id="usingCompany1" value="No" onChange={usingCompanyHandler} checked={props.aboutDetails.usingCompany === 'No'} />
                        <label className="form-check-label" for="usingCompany1">
                            No
                        </label>
                        <input className="form-check-input" type="radio" name="usingCompany" id="usingCompany2" value="Yes" onChange={usingCompanyHandler} />
                        <label className="form-check-label" for="usingCompany2">
                            Yes
                        </label>
                    </div>
                </div>
            </div>
            <div className="line_break"></div>
            <div>
                <div className="display-6 text-start">About the Sale</div>
                <div className="row pt-2 media-1">
                    <div className="col-4 media-width">
                        <label for="sale_price" className="text-start formLabel">Sale Price</label>
                    </div>
                    <div className="col input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">£</span>
                        </div>
                        <input type="number" min='1000' className="col form-control" id="salePrice" required />
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="price" className="text-start formLabel">Do you have a mortgage?</label>
                    </div>
                    <div className="col radio_btns">
                        <input className="form-check-input" type="radio" id="have_mortgage1" name="sale_have_mortgage" value="No" onChange={haveMortgageHandler} checked={props.saleFormData.gettingMortgage === 'No'} />
                        <label className="form-check-label" for="have_mortgage1">
                            No
                        </label>
                        <input className="form-check-input" type="radio" name="sale_have_mortgage" value="Yes" id="have_mortgage2" onChange={haveMortgageHandler} />
                        <label className="form-check-label" for="have_mortgage2">
                            Yes
                        </label>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="price" className="text-start formLabel">Is there bridging finance on this property?</label>
                    </div>
                    <div className="col t radio_btns">
                        <input className="form-check-input" type="radio" name="saleBridgingFinance" id="saleBridgingFinance1" value="No" onChange={bridgingFinanceHandler} checked={props.saleFormData.bridgingFinance === 'No'} />
                        <label className="form-check-label" for="saleBridgingFinance1">
                            No
                        </label>
                        <input className="form-check-input" type="radio" name="saleBridgingFinance" id="saleBridgingFinance2" value="Yes" onChange={bridgingFinanceHandler} />
                        <label className="form-check-label" for="saleBridgingFinance2">
                            Yes
                        </label>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="price" className="text-start formLabel">Who is your lender?</label>
                    </div>
                    <div className="col">
                        <select id="sale_mortgageLender" class="form-control">
                            <option value="-">Please select...</option>
                            <option value="Not Applicable">Not Applicable</option>
                            <option value="Unknown or unlisted lender">Unknown or unlisted lender</option>
                            <option value="Accord Mortgages">Accord Mortgages</option>
                            <option value="Aldemore">Aldemore</option>
                            <option value="Alliance & Leicester">Alliance & Leicester</option>
                            <option value="Atom Bank">Atom Bank</option>
                            <option value="Bank of Cyprus">Bank of Cyprus</option>
                            <option value="Bank of Ireland">Bank of Ireland</option>
                            <option value="Bank of Scotland">Bank of Scotland</option>
                            <option value="Barclays Bank UK Plc">Barclays Bank UK Plc</option>
                            <option value="Birmingham Midshires">Birmingham Midshires</option>
                            <option value="Bluestone Mortgages">Bluestone Mortgages</option>
                            <option value="BM Solutions">BM Solutions</option>
                            <option value="Britannia Buildings Society">Britannia Buildings Society</option>
                            <option value="Buckinghamshire Building Society">Buckinghamshire Building Society</option>
                            <option value="Cambridge Building Society">Cambridge Building Society</option>
                            <option value="Castle Trust Capital plc">Castle Trust Capital plc</option>
                            <option value="Cheltenham & Gloucester Building Society">Cheltenham & Gloucester Building Society</option>
                            <option value="Chelsea Building Society">Chelsea Building Society</option>
                            <option value="Chorley Building Society">Chorley Building Society</option>
                            <option value="Co-Operative Bank">Co-Operative Bank</option>
                            <option value="Cumberland Building Society">Cumberland Building Society</option>
                            <option value="Cynergy Bank">Cynergy Bank</option>
                            <option value="Danske Bank">Danske Bank</option>
                            <option value="Darlington Building Society">Darlington Building Society</option>
                            <option value="Dudley Building Society">Dudley Building Society</option>
                            <option value="Family Building Society">Family Building Society</option>
                            <option value="Foundation Home Loans">Foundation Home Loans</option>
                            <option value="Fleet Mortgages">Fleet Mortgages</option>
                            <option value="Guernsey Home Loans">Guernsey Home Loans</option>
                            <option value="Godiva Mortgages">Godiva Mortgages</option>
                            <option value="Halifax Building Society">Halifax Building Society</option>
                            <option value="Handels Banken">Handels Banken</option>
                            <option value="Help to Buy: ISA">Help to Buy: ISA</option>
                            <option value="Heritage Development Finance">Heritage Development Finance</option>
                            <option value="Hinckley and Rugby Building Society">Hinckley and Rugby Building Society</option>
                            <option value="HSBC">HSBC</option>
                            <option value="Interbay Commercial">Interbay Commercial</option>
                            <option value="Investec Bank">Investec Bank</option>
                            <option value="Ipswich Building Society">Ipswich Building Society</option>
                            <option value="Kensington Mortgages">Kensington Mortgages</option>
                            <option value="Jersey Home Loans">Jersey Home Loans</option>
                            <option value="Kensington Mortgages">Kensington Mortgages</option>
                            <option value="Kent Reliance">Kent Reliance</option>
                            <option value="Landbay - Buy to Let">Landbay - Buy to Let</option>
                            <option value="Leeds Building Society">Leeds Building Society</option>
                            <option value="Leek United Building Society">Leek United Building Society</option>
                            <option value="Lloyds Bank Group">Lloyds Bank Group</option>
                            <option value="Magellan Homeloans">Magellan Homeloans</option>
                            <option value="M&S Bank">M&S Bank</option>
                            <option value="Market Harborough Building Society">Market Harborough Building Society</option>
                            <option value="Marsden Building Society">Marsden Building Society</option>
                            <option value="Masthaven Bank">Masthaven Bank</option>
                            <option value="Melton Mowbray Building Society">Melton Mowbray Building Society</option>
                            <option value="Metro Bank">Metro Bank</option>
                            <option value="Monmouthshire Building Society">Monmouthshire Building Society</option>
                            <option value="National Counties Building Society & Family Building Society">National Counties Building Society & Family Building Society</option>
                            <option value="Nationwide Building Society">Nationwide Building Society</option>
                            <option value="Natwest">Natwest</option>
                            <option value="Newbury Building Society">Newbury Building Society</option>
                            <option value="Newcastle Building Society">Newcastle Building Society</option>
                            <option value="Nottingham Building Society">Nottingham Building Society</option>
                            <option value="One Savings Bank - Kent Reliance">One Savings Bank - Kent Reliance</option>
                            <option value="OSBIndia">OSBIndia</option>
                            <option value="Paragon Mortgages">Paragon Mortgages</option>
                            <option value="Platform - Co-operative Bank">Platform - Co-operative Bank</option>
                            <option value="Precise Mortgages">Precise Mortgages</option>
                            <option value="Prestige Finance">Prestige Finance</option>
                            <option value="Principality Building Society">Principality Building Society</option>
                            <option value="Sainsburys Bank">Sainsburys Bank</option>
                            <option value="Santander">Santander</option>
                            <option value="Scottish Building Society">Scottish Building Society</option>
                            <option value="Scottish Widows Bank">Scottish Widows Bank</option>
                            <option value="Secure Trust Bank">Secure Trust Bank</option>
                            <option value="Skipton Building Society">Skipton Building Society</option>
                            <option value="TSB Bank">Tesco Bank</option>
                            <option value="The Mortgage Lender">The Mortgage Lender</option>
                            <option value="The Mortgage Works">The Mortgage Works</option>
                            <option value="The Nottingham">The Nottingham</option>
                            <option value="The Paragon Group">The Paragon Group</option>
                            <option value="The Royal Bank of Scotland">The Royal Bank of Scotland</option>
                            <option value="The Woolwich">The Woolwich</option>
                            <option value="Tipton Coseley Building Society">Tipton Coseley Building Society</option>
                            <option value="Together">Together</option>
                            <option value="TSB Bank">TSB Bank</option>
                            <option value="TSB Lloyds Banking Group">TSB Lloyds Banking Group</option>
                            <option value="Virgin Money">Virgin Money</option>
                            <option value="West Bromich Building Society">West Bromich Building Society</option>
                            <option value="Yorkshire Building Society Group">Yorkshire Building Society Group</option>
                        </select>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="price" className="text-start formLabel">Freehold or leasehold property?</label>
                    </div>
                    <div className="col t radio_btns">
                        <input className="form-check-input" type="radio" name="sale_propertyHold" id="saleFreeorlease1" value="Freehold" onChange={propertyHoldHandler} checked={props.saleFormData.propertyHold === 'Freehold'} />
                        <label className="form-check-label" for="saleFreeorlease1">
                            Freehold
                        </label>
                        <input className="form-check-input" type="radio" name="sale_propertyHold" id="saleFreeorlease2" value="Leasehold" onChange={propertyHoldHandler} />
                        <label className="form-check-label" for="saleFreeorlease2">
                            Leasehold
                        </label>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="price" className="text-start formLabel">What type of property is it?</label>
                    </div>
                    <div className="col t radio_btns">
                        <input className="form-check-input" type="radio" name="sale_propertyType" id="saleProperty_type1" value="House" onChange={propertyTypeHandler} checked={props.saleFormData.propertyType === 'House'} />
                        <label className="form-check-label" for="saleProperty_type1">
                            House
                        </label>
                        <input className="form-check-input" type="radio" name="sale_propertyType" id="saleProperty_type2" value="Flat" onChange={propertyTypeHandler} />
                        <label className="form-check-label" for="saleProperty_type2">
                            Flat
                        </label>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="price" className="text-start formLabel">Number of Sellers</label>
                    </div>
                    <div className="col">
                        <select id="sale_numberSeller" className="form-control">
                            <option value="1">Just Me</option>
                            <option value="1">Me and 1 other</option>
                            <option value="3">3 People</option>
                            <option value="4">4 People</option>
                        </select>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="price" className="formLabel text-start">Did you use any of these purchase schemes when you bought the property?</label>
                    </div>
                    <div className="col">
                        <select id="sale_purchaseSchemes" className="form-control">
                            <option value="No">No</option>
                            <option value="Help to Buy Equity Loan Scheme for new build properties">Help to Buy Equity Loan Scheme for new build properties</option>
                            <option value="Help to Buy mortgage scheme">Help to Buy mortgage scheme</option>
                            <option value="Shared Ownership Scheme">Shared Ownership Scheme</option>
                        </select>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="isAuction" className="formLabel text-start">Is the property any of the following?</label>
                    </div>
                    <div className="col checkboxes">
                        <div className="check">
                            <div className="checkbox-1">
                                <input className="form-check-input" type="checkbox" name="sale_isAuction" id="saleIsAuction0" value="No" onChange={auctionChangeHandler} />
                                <label className="form-check-label" for="saleIsAuction0">No</label>
                            </div>
                            <div className="checkbox-1">
                                <input className="form-check-input" type="checkbox" name="sale_isAuction" id="saleIsAuction1" value="Unregistered" onChange={auctionChangeHandler} />
                                <label className="form-check-label" for="saleIsAuction1">Unregistered</label>
                            </div>
                        </div>
                        <div className="check">
                            <div className="checkbox-1">
                                <input className="form-check-input" type="checkbox" name="sale_isAuction" id="saleIsAuction2" value="Auction" onChange={auctionChangeHandler} />
                                <label className="form-check-label" for="saleIsAuction2">Auction</label>
                            </div>
                            <div className="checkbox-1">
                                <input className="form-check-input" type="checkbox" name="sale_isAuction" id="saleIsAuction3" value="Buy to Let" onChange={auctionChangeHandler} />
                                <label className="form-check-label" for="saleIsAuction3">Buy to Let</label>
                            </div>
                        </div>
                        <div className="check">
                            <div className="checkbox-1">
                                <input className="form-check-input" type="checkbox" name="sale_isAuction" id="saleIsAuction4" value="Related to Probate" onChange={auctionChangeHandler} />
                                <label className="form-check-label" for="saleIsAuction4">Related to Probate</label>
                            </div>
                            <div className="checkbox-1">
                                <input className="form-check-input" type="checkbox" name="sale_isAuction" id="saleIsAuction5" value="Matrimonial" onChange={auctionChangeHandler} />
                                <label className="form-check-label" for="saleIsAuction5">Matrimonial</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-4 media-width">
                        <label for="price" className="text-start formLabel">Is the property address known?</label>
                    </div>
                    <div className="col t radio_btns">
                        <input className="form-check-input" type="radio" name="sale_addressKnown" id="saleAddress_known1" value="No" onChange={addressKnownHandler} checked={props.saleFormData.addressKnown === 'No'} />
                        <label className="form-check-label" for="saleAddress_known1">
                            No
                        </label>
                        <input className="form-check-input" type="radio" name="sale_addressKnown" id="saleAddress_known2" value="Yes" onChange={addressKnownHandler} />
                        <label className="form-check-label" for="saleAddress_known2">
                            Yes
                        </label>
                    </div>
                </div>
                {props.saleFormData.addressKnown === 'Yes' ? (
                    <div className="row pt-3 media-1">
                        <div className="col-4 media-width">
                            <label for="price" className="text-start formLabel">Property Address Postcode</label>
                        </div>
                        <div className="col t radio_btns">
                            <input type="text" className="form-control" aria-describedby="basic-addon1" id="salePropertyAddressPostcode" name="salePropertyAddressPostcode" required="required" defaultValue={props.saleFormData.propertyAddressPostcode} onChange={addressPostcodeHandler} />
                        </div>
                    </div>
                ) : null}
            </div>
            <div class="line_break"></div>
            <div>
                <div className="h3 text-start">About You</div>
                <div className="row input-group pt-3 media-1">
                    <div className="col-2 media-width">
                        <label for="name" className="text-start formLabel">Name</label>
                    </div>
                    <div className="col-10 media-width">
                        <div className="input-group">
                            <select id="contactTitle" className="form-control" required="required" defaultValue={props.aboutDetails.title}>
                                <option value="">Title...</option>
                                <option value="Dr">Dr</option>
                                <option value="Miss">Miss</option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Ms">Ms</option>
                            </select>
                            <input id="contactForename" type="text" placeholder="Forename" className="form-control" required="required" defaultValue={props.aboutDetails.forename} />
                            <input id="contactSurname" type="text" placeholder="Surname" className="form-control" required="required" defaultValue={props.aboutDetails.surname} />
                        </div>
                    </div>
                </div>

                <div className="row pt-3 media-1">
                    <div className="col-2 media-width">
                        <label for="phone" className="text-start formLabel">Phone</label>
                    </div>
                    <div className="col-10 media-width">
                        <div class="input-group">
                            <span class="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faPhone} /></span>
                            <input type="text" className="form-control" aria-describedby="basic-addon1" id="phone" required="required" defaultValue={props.aboutDetails.enteredPhone} />
                        </div>
                    </div>
                </div>
                <div className="row pt-3 media-1">
                    <div className="col-2 media-width">
                        <label for="email" className="text-start formLabel">Email</label>
                    </div>
                    <div className="col-10 media-width">
                        <div class="input-group">
                            <span class="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faEnvelope} /></span>
                            <input type="email" className="form-control" aria-describedby="basic-addon1" id="email" required="required" defaultValue={props.aboutDetails.enteredEmail} />
                        </div>
                    </div>
                </div>
                {props.aboutDetails.usingCompany === 'Yes' ? (
                    <div className="companyDetails">
                        <div className="line_break"></div>
                        <div className="h3 text-start">Company Details</div>
                        <div className="row pt-3 media-1">
                            <div className="col-2 media-width">
                                <label for="companyName" className="text-start formLabel">Company Name</label>
                            </div>
                            <div className="col-10 media-width">
                                <div class="input-group">
                                    <input type="text" className="form-control" aria-describedby="basic-addon1" id="companyname" required="required" />
                                </div>
                            </div>
                        </div>
                        <div className="row pt-3 media-1">
                            <div className="col-2 media-width">
                                <label for="companyNumber" className="text-start formLabel">Company Address</label>
                            </div>
                            <div className="col-10 media-width">
                                <div class="input-group">
                                    <textarea className="form-control" aria-describedby="basic-addon1" id="companyaddress" required="required" defaultValue={props.aboutDetails.companyAddress} />
                                </div>
                            </div>
                        </div>
                        <div className="row pt-3 media-1">
                            <div className="col-2 media-width">
                                <label for="companyNumber" className="text-start formLabel">Company Number</label>
                            </div>
                            <div className="col-10 media-width">
                                <div class="input-group">
                                    <input type="text" className="form-control" aria-describedby="basic-addon1" id="companynumber" required="required" />
                                </div>
                            </div>
                        </div>
                        <br></br><br></br>
                        <div className="h3 text-start">Director details</div>
                        {[...Array(props.aboutDetails.companyDirectors)].map((elementInArray, index) => (
                            <Fragment>
                                <div className="row pt-3 media-1">
                                    <h3>Director {index + 1}</h3>
                                    <div className="col-2 media-width">
                                        <label for="companyNumber" className="text-start formLabel">Name</label>
                                    </div>
                                    <div className="col-10 media-width">
                                        <div class="input-group">
                                            <input type="text" className="form-control" aria-describedby="basic-addon1" id={"directorName" + parseInt(index + 1)} name={"directorName" + parseInt(index + 1)} required="required" placeholder="Director Name" onChange={e => directorNameHandler(e, parseInt(index + 1))} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-3 media-1">
                                    <div className="col-2 media-width">
                                        <label for="phone" className="text-start formLabel">Phone</label>
                                    </div>
                                    <div className="col-10 media-width">
                                        <div class="input-group">
                                            <span class="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faPhone} /></span>
                                            <input type="text" className="form-control" aria-describedby="basic-addon1" id={"directorPhone" + parseInt(index + 1)} name={"directorPhone" + parseInt(index + 1)} required="required" onChange={e => directorPhoneHandler(e, parseInt(index + 1))} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-3 media-1">
                                    <div className="col-2 media-width">
                                        <label for="phone" className="text-start formLabel">Email</label>
                                    </div>
                                    <div className="col-10 media-width">
                                        <div class="input-group">
                                            <span class="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faEnvelope} /></span>
                                            <input type="email" className="form-control" aria-describedby="basic-addon1" id={"directorEmail" + parseInt(index + 1)} name={"directorEmail" + parseInt(index + 1)} required="required" onChange={e => directorEmailHandler(e, parseInt(index + 1))} />
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )
                        )}
                        <br></br>
                        <button onClick={directorIncrementHandler} class="btn btn-dark">Add New Director</button>
                    </div>
                ) : null}
                <div className="line_break"></div>
                <div className="row consent">
                    <div className="col">
                        <input className="form-check-input" type="checkbox" name="consent" id="isConsent" required="required" />
                        <label className="form-check-label d-inline px-2" for="isConsent">
                            I consent to my personal information being processed by Wilford Smith and necessary third parties approved by Wilford Smith as detailed in their <span className="privacy_toggle" onClick={props.handleShow}>privacy statement</span>
                        </label>
                    </div>
                </div>
                <div className="row quote_submit">
                    <div className="col-12 text-center mt-4 mb-5">
                        <button type="submit" className="btn btn-success">Get My Quote Now</button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default SalePurchaseForm;