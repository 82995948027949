import {Fragment, useEffect, useState } from "react";
import { BlobProvider, Document, Page, Image, Text, View, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import db from "../../firebase";
import { collection, getDocs } from "firebase/firestore";

const PurchaseQuote = (props) => {
    const enteredPrice = props.formData.enteredPurchasePrice;
    const formType = props.formType;
    const [legalFee, setLegalFee] = useState(0);
    const [idCheck, setIdCheck] = useState(0);
    const [lawyerChecker, setLawyerChecker] = useState(0);
    const [ttFee, setTtFee] = useState(0);
    const [landSearch, setLandSearch] = useState(0);
    const [searches, setSearches] = useState(0);
    const [stampDutyLandTax, setStampDutyLandTax] = useState(0);
    const [leaseholdFlat, setLeaseholdFlat] = useState(0);
    const [leaseholdHouse, setLeaseholdHouse] = useState(0);
    const [newBuild, setNewBuild] = useState(0);
    const [sharedOwner, setSharedOwner] = useState(0);
    const [isaScheme, setIsaScheme] = useState(0);
    const [unregisteredProperty, setUnregisteredProperty] = useState(0);
    const [auctionSalePur, setAuctionSalePur] = useState(0);
    const [bridgingFinance, setBridgingFinance] = useState(0);
    const [companiesHouse, SetCompaniesHouse] = useState(0);
    const [companiesHouseCharge, SetCompaniesHouseCharge] = useState(0);
    const [landSearchCompany, setLandSearchCompany] = useState(0);
    const [specialLender, setSpecialLender] = useState(0);
    const [gifted, setGifted] = useState(0);
    const [stampDuty, setStampDuty] = useState(0);
    const [hmLandRegFee, setHmLandRegFee] = useState(0);
    const [totalQuote, setTotalQuote] = useState(0);
    const [totalQuoteWithoutStampDuty, setTotalQuoteWithoutStampDuty] = useState(0);
    const [ifNewBuild, setIfNewBuild] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [prices, setPrices] = useState(false);

    // Get Prices
    const getPrices = async () => {
        setLoading(true);
        const pricesCollectionRef = collection(db, "prices");
        await getDocs(pricesCollectionRef)
        .then((querySnapshot)=>{
            const pricesData = querySnapshot.docs
               .map((doc) => ({...doc.data(), id:doc.id }));
            setPrices(pricesData);
        })
        setLoading(false);
    }

    let pdfbase = '';
    //Instruct Click
    const instructClickHandler = () => {
        props.instructClickHandler(pdfbase);
    };

    const feeCalculations = () => {
        console.log(props);
        if(prices.length > 0) {
            setLoading(true);
            setIdCheck(parseInt(prices[0].idCheck) * props.formData.numberSellers);
            setLawyerChecker(parseInt(prices[0].lawyerChecker));
            setTtFee(parseInt(prices[0].ttFee));
            setLandSearch(parseInt(prices[0].landSearch));
            setSearches(parseInt(prices[0].searches));
            if(formType === 'Purchase' || formType === 'Remortgage') {
                setStampDutyLandTax(parseInt(prices[0].stampDutyLandTax));
            }
            if(props.source === 'Mortgage Advice UK / Whitehorns') {
                setDiscount(100);
            }
            if(props.formData.propertyType === 'Flat' && props.formData.propertyHold === 'Leasehold') {
                setLeaseholdFlat(parseInt(prices[0].leaseholdFlat));
            } else if(props.formData.propertyType === 'House' && props.formData.propertyHold === 'Leasehold') {
                setLeaseholdHouse(parseInt(prices[0].leaseholdHouse));
            }
            if (props.auctionInfo[1].checked === true) {
                setNewBuild(parseInt(prices[0].newBuild));
                setIfNewBuild(true);
            }
            if (props.auctionInfo[2].checked === true) {
                setUnregisteredProperty(parseInt(prices[0].unregisteredProperty));
            }
            if(props.auctionInfo[3].checked === true) {
                setAuctionSalePur(parseInt(prices[0].auctionSalePur));
            }
            if(props.formData.mortgageLender === 'Castle Trust Capital plc' || props.formData.mortgageLender === 'Fleet Mortgages' || props.formData.mortgageLender === 'Foundation Home Loans' || props.formData.mortgageLender === 'Interbay Commercial' || props.formData.mortgageLender === 'Landbay - Buy to Let' || props.formData.mortgageLender === 'Paragon Mortgages' || props.formData.mortgageLender === 'The Paragon Group' || props.formData.mortgageLender === 'Precise Mortgages') {
                setSpecialLender(parseInt(250));
            }
            if(props.formData.gifted === 'Yes') {
                setGifted(parseInt(125));
            }
            if(props.formData.purchaseSchemes === 'Shared Ownership Scheme') {
                setSharedOwner(parseInt(prices[0].sharedOwner));
            }
            if(props.formData.purchaseSchemes === 'Help to Buy ISA scheme') {
                setIsaScheme(parseInt(prices[0].isaScheme * props.formData.numberSellers));
            }
            if(props.formData.bridgingFinance === 'Yes') {
                setBridgingFinance(parseInt(prices[0].bridgingFinance));
            }
            if(props.aboutDetails.usingCompany === 'Yes' && props.formData.gettingMortgage === 'Yes') {
                SetCompaniesHouse(parseInt(prices[0].companiesHouse));
                SetCompaniesHouseCharge(parseInt(prices[0].companiesHouseCharge));
                setLandSearchCompany(parseInt(prices[0].landSearchCompany));
            }
            if(enteredPrice < 80001) {
                ifNewBuild === true ? (
                    setHmLandRegFee(parseInt(prices[0].landRegFee1NB))
                ) : (
                    setHmLandRegFee(parseInt(prices[0].landRegFee1))
                )
            } else if(enteredPrice > 80000 && enteredPrice < 100001) {
                ifNewBuild === true ? (
                    setHmLandRegFee(parseInt(prices[0].landRegFee2NB))
                ) : (
                    setHmLandRegFee(parseInt(prices[0].landRegFee2))
                )
            } else if(enteredPrice > 100000 && enteredPrice < 200001) {
                ifNewBuild === true ? (
                    setHmLandRegFee(parseInt(prices[0].landRegFee3NB))
                ) : (
                    setHmLandRegFee(parseInt(prices[0].landRegFee3))
                )
            } else if(enteredPrice > 200000 && enteredPrice < 500001) {
                ifNewBuild === true ? (
                    setHmLandRegFee(parseInt(prices[0].landRegFee4NB))
                ) : (
                    setHmLandRegFee(parseInt(prices[0].landRegFee4))
                )
            } else if(enteredPrice > 500000 && enteredPrice < 1000001) {
                ifNewBuild === true ? (
                    setHmLandRegFee(parseInt(prices[0].landRegFee5NB))
                ) : (
                    setHmLandRegFee(parseInt(prices[0].landRegFee5))
                )
            } else if(enteredPrice > 1000000) {
                ifNewBuild === true ? (
                    setHmLandRegFee(parseInt(prices[0].landRegFee6NB))
                ) : (
                    setHmLandRegFee(parseInt(prices[0].landRegFee6))
                )
            }
            //Base Fee Calculation
            if(enteredPrice < 150001) {
                setLegalFee(parseInt(prices[0].legalFee1));
            } else if(enteredPrice > 150000 && enteredPrice < 250001) {
                setLegalFee(parseInt(prices[0].legalFee2));
            } else if(enteredPrice > 250000 && enteredPrice < 500001) {
                setLegalFee(parseInt(prices[0].legalFee3));
            } else if(enteredPrice > 500000 && enteredPrice < 750001) {
                setLegalFee(parseInt(prices[0].legalFee4));
            } else if(enteredPrice > 750000 && enteredPrice < 1000001) {
                setLegalFee(parseInt(prices[0].legalFee5));
            } else if(enteredPrice > 1000000 && enteredPrice < 2000001) {
                setLegalFee(parseInt(prices[0].legalFee6));
            } else if(enteredPrice > 2000000) {
                setLegalFee(parseInt(Number(enteredPrice) * 0.10 / 100) );
            }
            setLoading(false);
        }
    };

    const calculateTotal = () => {
        if(legalFee > 0) {
            setLoading(true);
            
            setTotalQuote(legalFee + idCheck + lawyerChecker + ttFee + stampDutyLandTax + stampDuty + leaseholdFlat + leaseholdHouse + newBuild + sharedOwner + gifted + isaScheme + unregisteredProperty + auctionSalePur + bridgingFinance + searches + companiesHouse + landSearch + landSearchCompany + specialLender + hmLandRegFee);
            
            setTotalQuoteWithoutStampDuty(legalFee + idCheck + lawyerChecker + ttFee + stampDutyLandTax + leaseholdFlat + leaseholdHouse + newBuild + sharedOwner + gifted + isaScheme + unregisteredProperty + auctionSalePur + bridgingFinance + searches + companiesHouse +  landSearch + landSearchCompany + specialLender + hmLandRegFee);
            setLoading(false);
        }
    }

    useEffect(() => {
        const loadAsyncStuff = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://api.propertydata.co.uk/stamp-duty-calculator?key=FVDWI5SWRS&value=' + enteredPrice);
                const json = await response.json();
                setStampDuty(json.transaction_tax_payable);
            } catch (error) {
                console.log(error);
            } finally {
                console.log(true);
            }
            };
            loadAsyncStuff();
            setLoading(false);
    }, []);

    useEffect(() => {
        getPrices();
    }, [stampDuty]);

    useEffect(() => {
        feeCalculations();
    }, [prices, ifNewBuild]);

    useEffect(() => {
        calculateTotal();
    }, [legalFee]);

    // Create styles
    const styles = StyleSheet.create({
        page: {
            backgroundColor: "#fff",
            color: "#000",
            padding: 50,
        },
        title: {
            fontSize: 16,
            padding:10,
            textDecoration: "underline",
            width: '100%',
            textAlign: 'center',
        },
        text: {
            fontSize: 12,
            padding:10,
            textAlign: 'left',
            display: 'flex',
            alignContent: 'center',
        },
        section: {
            margin: 10,
            padding: 10,
        },
        viewer: {
            width: window.innerWidth,
            height: window.innerHeight,
        },
        table: { 
            display: "table", 
            width: "auto", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderRightWidth: 1, 
            borderBottomWidth: 1,
            borderTopWidth: 1,
            borderLeftWidth: 1,
            width: "80%"
        }, 
        tableRow: { 
            margin: "auto", 
            flexDirection: "row",
        },
        tableRowFirstLine: { 
            margin: "auto", 
            flexDirection: "row",
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderRightWidth: 0, 
            borderBottomWidth: 0, 
            borderTopWidth: 1,
            backgroundColor: '#000',
            color: '#fff',
        },
        tableColFullWidth: { 
            width: '100%',
            margin: "0", 
            flexDirection: "row",
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderRightWidth: 0, 
            borderBottomWidth: 0, 
            borderTopWidth: 1,
            backgroundColor: '#000',
            color: '#fff',
            backgroundColor: '#333333',
        },
        tableColfirst: { 
            width: "50%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 1, 
            borderTopWidth: 0 ,
        },
        tableColfirstDark: {
            width: "50%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 1, 
            borderTopWidth: 0 ,
            color: '#fff',
            backgroundColor: '#333333',
        },
        tableColGreyFirst: {
            width: "50%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 1, 
            borderTopWidth: 0 ,
            color: '#fff',
            backgroundColor: '#cccccc',
        },
        tableColGrey: {
            width: "25%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 1, 
            borderTopWidth: 0 ,
            color: '#fff',
            backgroundColor: '#cccccc',
        },
        tableCol: { 
            width: "25%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0 
        },
        tableCol50: {
            width: "50%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0 
        },
        tableCell: { 
            margin: "auto", 
            marginTop: 5, 
            fontSize: 10 
        },
        container: {
            padding: 10,
        },
        logo: {
            width: 200,
            padding: 10,
        }
    });
    const MyDoc = () => (
        <Document>
          <Page size="A4" style={styles.page}>
            <View style="styles.section">
                <Image src="/images/logo.png" style={styles.logo} />
                <Text style={styles.title}>Conveyancing Estimate</Text>
                <Text style={styles.text}>Thank you for your interest in our legal services. Please find below an Estimate for fees and disbursements in connection with your transaction at £{Math.round(parseInt(totalQuoteWithoutStampDuty - discount) + parseInt(totalQuoteWithoutStampDuty - discount)*20/100 - parseInt(hmLandRegFee*20/100))}</Text>
                <View style={styles.container}>
                    <View style={styles.tableRowFirstLine}>
                        <View style={styles.tableColfirst}>
                            <Text style={styles.tableCell}>Service</Text> 
                        </View> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>Fee Exc. Vat</Text> 
                        </View> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>Vat</Text> 
                        </View> 
                    </View>
                    <View style={styles.tableRowFirstLine}>
                        <View style={styles.tableColFullWidth}>
                            <Text style={styles.tableCell}>Legal fee</Text> 
                        </View> 
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColfirst}> 
                            <Text style={styles.tableCell}>Legal Fee</Text> 
                        </View> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>£{legalFee}</Text> 
                        </View> 
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>£{legalFee * 20 /100}</Text> 
                        </View>
                    </View>
                    <View style={styles.tableRowFirstLine}>
                        <View style={styles.tableColFullWidth}>
                            <Text style={styles.tableCell}>Additional costs added to base legal fee</Text> 
                        </View> 
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColfirst}> 
                            <Text style={styles.tableCell}>ID Check</Text> 
                        </View> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>£{idCheck}</Text> 
                        </View> 
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>£{idCheck * 20 /100}</Text> 
                        </View>
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColfirst}> 
                            <Text style={styles.tableCell}>Lawyer Checker Fee</Text> 
                        </View> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>£{lawyerChecker}</Text> 
                        </View> 
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>£{lawyerChecker * 20 /100}</Text> 
                        </View>
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColfirst}> 
                            <Text style={styles.tableCell}>TT Fee</Text> 
                        </View> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>£{ttFee}</Text> 
                        </View> 
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>£{ttFee * 20 /100}</Text> 
                        </View>
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColfirst}> 
                            <Text style={styles.tableCell}>Stamp Duty Land Tax Form</Text> 
                        </View> 
                        <View style={styles.tableCol}> 
                            <Text style={styles.tableCell}>£{stampDutyLandTax}</Text> 
                        </View> 
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>£{stampDutyLandTax * 20 /100}</Text> 
                        </View>
                    </View>
                    {props.formData.propertyType === 'Flat' && props.formData.propertyHold === 'Leasehold' ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Leasehold flat/apartment</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{leaseholdFlat}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{leaseholdFlat * 20 /100}</Text> 
                                </View>
                            </View>
                        ) : props.formData.propertyType === 'House' && props.formData.propertyHold === 'Leasehold' ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Leasehold house</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{leaseholdHouse}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{leaseholdHouse * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {newBuild > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>New build property</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{newBuild}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{newBuild * 20 /100}</Text> 
                                </View>
                            </View>
                        ):null }
                        {sharedOwner > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Shared ownership</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{sharedOwner}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{sharedOwner * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {specialLender > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Special Lender</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{specialLender}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{specialLender * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {gifted > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Gifted Property Fee</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{gifted}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{gifted * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {isaScheme > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Help to Buy ISA scheme</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{isaScheme}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{isaScheme * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {props.auctionInfo[1].checked === true ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Unregistered property fee</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{unregisteredProperty}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{unregisteredProperty * 20 /100}</Text> 
                                </View>
                            </View>
                        ):null }
                        {props.auctionInfo[2].checked === true && (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Auction sale / purchase</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{auctionSalePur}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{auctionSalePur * 20 /100}</Text> 
                                </View>
                            </View>
                        )}
                        {bridgingFinance > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Bridging finance</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{bridgingFinance}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{bridgingFinance * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {companiesHouse > 0 ?(
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Dealing with Limited Company</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{companiesHouse}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{companiesHouse * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {companiesHouseCharge > 0 ?(
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Companies House charge (per charge)</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{companiesHouseCharge}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{companiesHouseCharge * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        {landSearchCompany > 0 ? (  
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColfirst}> 
                                    <Text style={styles.tableCell}>Land Registry & Bankruptcy searches (company)</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>£{landSearchCompany}</Text> 
                                </View> 
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>£{landSearchCompany * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>Land Registry & Bankruptcy searches</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>£{landSearch}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£{landSearch * 20 /100}</Text> 
                            </View>
                        </View>
                        <View style={styles.tableRowFirstLine}>
                        <View style={styles.tableColFullWidth}>
                            <Text style={styles.tableCell}>Third party disbursements</Text> 
                        </View> 
                    </View>
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>Search Fee</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>£{searches}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£0</Text> 
                            </View>
                        </View>
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>Stamp Duty</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>£{stampDuty}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£0</Text> 
                            </View>
                        </View>
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>HM Land Registration fee</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>£{hmLandRegFee}</Text> 
                            </View> 
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>£0</Text> 
                            </View>
                        </View>
                        {discount > 0 ? (
                            <View style={styles.tableRow}> 
                                <View style={styles.tableColGreyFirst}> 
                                    <Text style={styles.tableCell}>Discount</Text> 
                                </View> 
                                <View style={styles.tableColGrey}> 
                                    <Text style={styles.tableCell}>£{discount}</Text> 
                                </View> 
                                <View style={styles.tableColGrey}>
                                    <Text style={styles.tableCell}>£{discount * 20 /100}</Text> 
                                </View>
                            </View>
                        ) :null}
                        <View style={styles.tableRow}> 
                            <View style={styles.tableColfirst}> 
                                <Text style={styles.tableCell}>Total costs including VAT and disbursements</Text> 
                            </View> 
                            {discount > 0 ? (
                                <View style={styles.tableCol50}>
                                    <Text style={styles.tableCell}>£{Math.round(parseInt(totalQuoteWithoutStampDuty - discount) + parseInt(totalQuoteWithoutStampDuty - discount)*20/100 - parseInt(hmLandRegFee*20/100) - parseInt(searches*20/100))}</Text> 
                                </View>
                            ) : (
                                <View style={styles.tableCol50}>
                                    <Text style={styles.tableCell}>£{Math.round(totalQuoteWithoutStampDuty + totalQuoteWithoutStampDuty*20/100 - parseInt(hmLandRegFee*20/100) - parseInt(searches*20/100))}</Text>
                                </View>
                            )}
                        </View>
                    </View>
                    <Text style={styles.text}>
                        At Wilford Smith Solicitors, we pride ourselves on customer service. We will only ever be at the end of the phone or email if you ever have any queries.
                    </Text>
                    <Text style={styles.text}>
                        If you would like to instruct us to proceed, please reply by return of email or call us on 0808 1695677:
                    </Text>
                    <Text style={styles.text}>
                        ** The fees and disbursement, where applicable, are inclusive of VAT at current rates and that should the VAT 
                        rate change the estimate will be subject to change. 
                    </Text>
                    <Text style={styles.text}>
                        The estimate is based on the information available at the time the estimate was given but additional charges and disbursements, and VAT where appropriate, may apply depending on the nature of the transaction.
                    </Text>
                    <Text style={styles.text}>
                        Subject to the above this estimate will be valid until the expiry of 28 days the starting date for which will be the day after the estimate was given.
                    </Text>
                </View>
            </Page>
        </Document>
    );

    return (
        <div>
            <div className="display-6 text-start">Your Estimate</div>
            <div className="quote_big">
                {discount > 0 ? (
                    "£"+Math.round(parseInt(totalQuoteWithoutStampDuty-discount) + parseInt(totalQuoteWithoutStampDuty-discount)*20/100 - parseInt(hmLandRegFee*20/100))
                ) : (
                    "£"+Math.round(totalQuoteWithoutStampDuty + totalQuoteWithoutStampDuty*20/100 - parseInt(hmLandRegFee*20/100))
                )}
            </div>
            <p className="quote_subtext">
                This includes all your legal fees, disbursements & VAT.
                <br></br>
                Excludes Stamp Duty.
            </p>
            <table className="table">
                <thead>
                    <tr>
                        <th>{formType} Quote</th>
                        <th>Exc. VAT</th>
                        <th>VAT</th>
                        <th>Inc. VAT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="table-primary">
                        <td colSpan="4">Legal Fees</td>
                    </tr>
                    <tr className="fee">
                        <td>Legal Fees</td>
                        <td>£{legalFee}</td>
                        <td>£{legalFee * 20 /100}</td>
                        <td>£{legalFee + legalFee*20/100}</td>
                    </tr>
                    <tr className="table-primary">
                        <td colSpan="4">Additional costs added to base legal fee</td>
                    </tr>
                    <tr className="fee">
                        <td>ID check</td>
                        <td>£{idCheck}</td>
                        <td>£{idCheck*20/100}</td>
                        <td>£{idCheck + idCheck*20/100}</td>
                    </tr>
                    <tr className="fee">
                        <td>Lawyer Checker fee</td>
                        <td>£{lawyerChecker}</td>
                        <td>£{lawyerChecker*20/100}</td>
                        <td>£{lawyerChecker + lawyerChecker*20/100}</td>
                    </tr>
                    <tr className="fee">
                        <td>TT fee (per payment)</td>
                        <td>£{ttFee}</td>
                        <td>£{ttFee*20/100}</td>
                        <td>£{ttFee + ttFee*20/100}</td>
                    </tr>
                    <tr className="fee">
                        <td>Stamp Duty Land Tax Form</td>
                        <td>£{stampDutyLandTax}</td>
                        <td>£{stampDutyLandTax*20/100}</td>
                        <td>£{stampDutyLandTax + stampDutyLandTax*20/100}</td>
                    </tr>
                    {props.formData.propertyType === 'Flat' && props.formData.propertyHold === 'Leasehold' ? (
                        <tr className="fee">
                            <td>Leasehold flat/apartment</td>
                            <td>£{leaseholdFlat}</td>
                            <td>£{leaseholdFlat*20/100}</td>
                            <td>£{leaseholdFlat + leaseholdFlat*20/100}</td>
                        </tr>
                    ) : props.formData.propertyType === 'House' && props.formData.propertyHold === 'Leasehold' ? (
                        <tr className="fee">
                            <td>Leasehold house</td>
                            <td>£{leaseholdHouse}</td>
                            <td>£{leaseholdHouse*20/100}</td>
                            <td>£{leaseholdHouse + leaseholdHouse*20/100}</td>
                        </tr>
                    ) :null}
                    {newBuild > 0 ? (
                        <tr className="fee">
                            <td>New build property</td>
                            <td>£{newBuild}</td>
                            <td>£{newBuild*20/100}</td>
                            <td>£{newBuild + newBuild*20/100}</td>
                        </tr>
                    ):null }
                    {sharedOwner > 0 ? (
                        <tr className="fee">
                            <td>Shared ownership</td>
                            <td>£{sharedOwner}</td>
                            <td>£{sharedOwner*20/100}</td>
                            <td>£{sharedOwner + sharedOwner*20/100}</td>
                        </tr>
                    ) :null}
                    {specialLender > 0 ? (
                        <tr className="fee">
                            <td>Special Lender Fee</td>
                            <td>£{specialLender}</td>
                            <td>£{specialLender*20/100}</td>
                            <td>£{specialLender + specialLender*20/100}</td>
                        </tr>
                    ) :null}
                    {gifted > 0 ? (
                        <tr className="fee">
                            <td>Gifted Property Fee</td>
                            <td>£{gifted}</td>
                            <td>£{gifted*20/100}</td>
                            <td>£{gifted + gifted*20/100}</td>
                        </tr>
                    ) :null}
                    {isaScheme > 0 ? (
                        <tr className="fee">
                            <td>Help to Buy ISA scheme</td>
                            <td>£{isaScheme}</td>
                            <td>£{isaScheme*20/100}</td>
                            <td>£{isaScheme + isaScheme*20/100}</td>
                        </tr>
                    ) :null}
                    {props.auctionInfo[1].checked === true ? (
                        <tr className="fee">
                            <td>Unregistered property fee</td>
                            <td>£{unregisteredProperty}</td>
                            <td>£{unregisteredProperty*20/100}</td>
                            <td>£{unregisteredProperty + unregisteredProperty*20/100}</td>
                        </tr>
                    ):null }
                    {props.auctionInfo[2].checked === true ? (
                        <tr className="fee">
                            <td>Auction sale / purchase</td>
                            <td>£{auctionSalePur}</td>
                            <td>£{auctionSalePur*20/100}</td>
                            <td>£{auctionSalePur + auctionSalePur*20/100}</td>
                        </tr>
                    ):null }
                    {bridgingFinance > 0 ? (
                        <tr className="fee">
                            <td>Bridging finance</td>
                            <td>£{bridgingFinance}</td>
                            <td>£{bridgingFinance*20/100}</td>
                            <td>£{bridgingFinance + bridgingFinance*20/100}</td>
                        </tr>
                    ) :null}
                    {companiesHouse > 0 ?(
                        <tr className="fee">
                            <td>Dealing with Limited Company</td>
                            <td>£{companiesHouse}</td>
                            <td>£{companiesHouse*20/100}</td>
                            <td>£{companiesHouse + companiesHouse*20/100}</td>
                        </tr>
                    ) :null}
                    {companiesHouseCharge > 0 ?(
                        <tr className="fee">
                            <td>Companies House charge (per charge)</td>
                            <td>£{companiesHouseCharge}</td>
                            <td>£{companiesHouseCharge*20/100}</td>
                            <td>£{companiesHouseCharge + companiesHouseCharge*20/100}</td>
                        </tr>
                    ) :null}
                    {landSearchCompany > 0 ? (
                        <tr className="fee">
                            <td>Land Registry & Bankruptcy searches (company)</td>
                            <td>£{landSearchCompany}</td>
                            <td>£{landSearchCompany*20/100}</td>
                            <td>£{landSearchCompany + landSearchCompany*20/100}</td>
                        </tr>
                    ) :null}
                    <tr className="fee">
                        <td>Land Registry & Bankruptcy searches</td>
                        <td>£{landSearch}</td>
                        <td>£{landSearch*20/100}</td>
                        <td>£{landSearch + landSearch*20/100}</td>
                    </tr>
                    <tr className="table-primary">
                        <td colSpan="4">Third party disbursements</td>
                    </tr>
                    <tr className="fee">
                        <td>Search Fee</td>
                        <td>£{searches}</td>
                        <td>0</td>
                        <td>£{Math.round(searches)}</td>
                    </tr>
                    <tr className="fee">
                        <td>Stamp Duty</td>
                        <td>£{stampDuty}</td>
                        <td>0</td>
                        <td>£{stampDuty}</td>
                    </tr>
                    <tr className="fee">
                        <td>HM Land Registration fee</td>
                        <td>£{hmLandRegFee}</td>
                        <td>0</td>
                        <td>£{hmLandRegFee}</td>
                    </tr>
                    <tr className="table-success">
                        <td>Total</td>
                        <td>£{totalQuoteWithoutStampDuty}</td>
                        <td>£{totalQuoteWithoutStampDuty*20/100 - parseInt(hmLandRegFee*20/100 ) - parseInt(searches*20/100)}</td>
                        <td>£{Math.round(totalQuoteWithoutStampDuty + totalQuoteWithoutStampDuty*20/100 - parseInt(hmLandRegFee*20/100) - parseInt(searches*20/100))}</td>
                    </tr>
                    {discount > 0 ? (
                        <>
                            <tr className="table-secondary">
                                <td>Discount</td>
                                <td>£{discount}</td>
                                <td>£{discount*20/100}</td>
                                <td>£{discount + discount*20/100}</td>
                            </tr>
                            <tr className="table-success">
                                <td>Total After Discount</td>
                                <td>£{totalQuoteWithoutStampDuty - discount}</td>
                                <td>£{parseInt(totalQuoteWithoutStampDuty-discount)*20/100 - parseInt(hmLandRegFee*20/100)}</td>
                                <td>£{Math.round(parseInt(totalQuoteWithoutStampDuty-discount) + parseInt(totalQuoteWithoutStampDuty-discount)*20/100 - parseInt(hmLandRegFee*20/100))}</td>
                            </tr>
                        </>
                    ) :null}
                </tbody>
            </table>

            <div className="quote_actions">
                <button onClick={instructClickHandler} className="btn btn-success mx-2">Submit</button>
                <PDFDownloadLink document={<MyDoc />} fileName="quote.pdf" className="print_icon">
                    {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : <FontAwesomeIcon icon={faPrint} />
                    }
                </PDFDownloadLink>
                <BlobProvider document={<MyDoc />}>
                    {({ blob, url, loading, error }) => {
                        const pdf2base64 = require('pdf-to-base64');
                            pdf2base64(url, {type: "application/pdf"})
                                .then(
                                    (response) => {
                                        // setPdfCode(response)
                                        pdfbase = response
                                    }
                                )
                                .catch(
                                    (error) => {
                                        console.log(error); //Exepection error....
                                    }
                                )
                    }}
                </BlobProvider>
            </div>
            {loading === true ? (
                <div className="loading">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ): null}
        </div>
    )
}

export default PurchaseQuote;